input:not([felloTheme] *),
textarea:not([felloTheme] *) {
  border: 1px solid var(--border-color);
}
label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  margin-bottom: 10px;
}

input:not([felloTheme] *) {
  background: var(--white);
  border: 1px solid var(--slate-2);
  border-radius: var(--border-radius);
  padding: 8px 12px;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 991px) {
    &:disabled:not(.fake-disabled) {
      opacity: 1;
      background-color: var(--slate-2);
      pointer-events: none;
    }
  }
}

.form-field {
  &.prefix {
    input[type="text"],
    input[type="number"] {
      padding-left: 25px;
    }

    &.small {
      input[type="text"],
      input[type="number"] {
        padding-left: 18px;
      }

      &:after {
        font-size: 12px;
        line-height: 17px;
        top: 6px;
        left: 7px;
      }
    }
  }
  &.suffix {
    input[type="text"],
    input[type="number"] {
      padding-right: 25px;
    }

    &.small {
      input[type="text"],
      input[type="number"] {
        padding-right: 18px;
      }

      &:before {
        font-size: 12px;
        line-height: 17px;
        top: 6px;
        right: 7px;
      }
    }
  }

  &.prefix-icon {
    input[type="text"],
    input[type="number"] {
      padding-left: 28px;
    }

    lib-icon {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:after {
    top: 9px;
    left: 12px;
    font-size: 16px;
    line-height: 24px;
    color: var(--slate-5);
  }

  &:before {
    top: 9px;
    right: 12px;
    font-size: 16px;
    line-height: 24px;
    color: var(--slate-5);
  }

  mat-datepicker-toggle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);
  }

  &.small {
    mat-datepicker-toggle {
      transform: translateY(-50%) scale(0.8);
    }
  }

  .arrow {
    border: solid var(--color-primary);
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 3px;
    transform: translateY(calc(-50% - 1.5px)) rotate(45deg);
    position: absolute;
    top: 50%;
    right: 8px;
    pointer-events: none;
  }
}

@media (min-width: 768px) {
  input[type="text"]:not([felloTheme] *),
  input[type="password"]:not([felloTheme] *),
  textarea {
    font-size: 16px;
  }
}

.consent-checkbox {
  transform: translateY(2px);
  opacity: 1 !important;
}

.map-marker {
  --marker-color: var(--slate-4);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: var(--marker-color);
  border-style: solid;
  background: var(--white);
  box-shadow: 0 2px 4px 0 #15151526;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: 500;
  color: var(--slate-4) !important;
  min-width: 25px;
  position: relative;
  text-transform: uppercase;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 100%;
    left: 50%;
    z-index: 0;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--marker-color);
  }
}

.view-propensity .map-marker {
  background-color: var(--marker-color);
  color: var(--white) !important;
  border-radius: 100px;
  padding: 1.5px 7px;

  &.ps-very-high {
    --marker-color: var(--green-6);
  }

  &.ps-high {
    --marker-color: var(--green-4);
  }

  &.ps-moderate {
    --marker-color: var(--yellow-6);
  }

  &.ps-low {
    --marker-color: var(--orange-6);
  }

  &.ps-very-low {
    --marker-color: var(--red-6);
  }
}

.view-avm .map-marker {
  border-radius: 4px;
  border-color: var(--plum-5);
  background-color: var(--plum-5);
  color: var(--white) !important;

  &:before {
    border-top-color: var(--plum-5);
  }
}

.view-price .map-marker {
  border-radius: 4px;
  border-color: var(--slate-2);
  background-color: var(--white);
  color: var(--plum-5) !important;

  &.active::before {
    --marker-color: var(--blue);
  }

  &.expired::before {
    --marker-color: var(--red);
  }

  &.deleted::before {
    --marker-color: var(--red);
  }

  &.pending::before {
    --marker-color: var(--purple);
  }

  &.sold::before,
  &.sale::before {
    --marker-color: var(--yellow-4);
  }
}

.map-marker.non-marketing {
  font-size: 0 !important;
  background-image: url("/assets/images/svg/lock.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  background-color: var(--white);
  border-color: var(--slate-4);
  padding: 6px 4px;

  &:before {
    border-top-color: var(--slate-4);
  }
}

.view-propensity .map-marker.no-propensity-data,
.view-price .map-marker.no-price-data,
.view-avm .map-marker.no-avm-data,
.dot-markers .map-marker {
  height: 0;
  width: 0;
  min-width: 0;
  font-size: 0 !important;
  border-radius: 50%;
  padding: 5px;
  background: var(--slate-4);
  border: 2px solid var(--white);
  box-shadow: 0 1.5px 3px 0 #56697d;

  &.non-marketing {
    background: var(--slate-4);
    border: 2px solid var(--white);
  }

  &:before {
    display: none;
  }
}

.dot-markers.dot-markers .map-marker {
  background-color: var(--plum-5) !important;
}

.map-marker.highlighted {
  transform: scale(1.4);
  transform-origin: bottom;

  &:not(.non-marketing) {
    background: var(--color-primary) !important;
    color: var(--white) !important;
    border: none;

    &::before {
      border-top-color: var(--color-primary) !important;
    }
  }
}

.map-marker.multiple-units {
  --marker-color: var(--plum-5);
  text-transform: none;
  background: var(--marker-color);
  color: var(--white) !important;
}

@use "./buttons";

.md-drppicker.md-drppicker:not(.fello-date-picker) {
  display: flex;
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: 16px 16px 66px 16px;

  &:before,
  &:after {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }

  .calendar {
    margin: 0;
  }

  .calendar-table {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
  }

  .buttons {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }

  .buttons_input {
    display: flex;
    align-items: center;
    gap: 20px;

    button {
      box-shadow: none;
    }

    svg {
      display: none;
    }
  }

  // disabled controls
  td,
  option {
    &.disabled {
      color: var(--slate-00);
    }
  }

  .ranges {
    margin-right: 16px;

    ul {
      li {
        button {
          font-weight: 600;
          font-size: 12px;
          background: none;
          margin-bottom: 8px;
          padding: 5px 10px;
          color: var(--text-color);
          white-space: nowrap;
          text-align: left;
          display: block;

          &.active {
            background-color: var(--color-secondary);
            border-radius: 4px;
            color: var(--white);
          }
        }
      }
      li:hover {
        background-color: #eee;
      }
    }
  }

  .btn {
    @extend .button;
    @extend .button-small;

    &:hover,
    &:focus {
      background-color: var(--color-primary);
    }
  }

  td.active,
  td.active:hover {
    background-color: var(--color-primary);
  }

  td {
    border-radius: 10px;
    opacity: 1;

    &.off {
      &,
      &.in-range,
      &.start-date,
      &.end-date {
        color: var(--plum-4);
      }
    }

    //
    // Date Range
    &.in-range {
      background-color: var(--coral-1);
    }

    &.start-date {
      border-radius: 10px 0 0 10px;
    }

    &.end-date {
      border-radius: 0 10px 10px 0;
    }

    &.start-date.end-date {
      border-radius: 10px;
    }

    &.active {
      background: var(--color-primary);
    }
  }
}

.hide-timer + ngx-daterangepicker-material {
  .calendar-time {
    display: none;
  }
}
.mat-calendar-body-cell-content.mat-calendar-body-cell-content {
  border-radius: 10px;
  font-size: 14px;
  width: 75%;
  height: 75%;

  &.mat-calendar-body-selected {
    background-color: white;
    border: 1px solid var(--color-primary);
  }
}

.step {
    padding-left: 24px;
    position: relative;

    .complete-tick {
        display: none;
    }

    &:not(:last-child) {
        margin-bottom: 24px;

        &::before {
            content: "";
            position: absolute;
            height: calc(100% + 24px);
            width: 2px;
            left: 6px;
            top: 15px;
            background: var(--slate-2);
            opacity: 0.4;
        }
    }

    &:not(.complete) {
        .title {
            font-weight: 700;
        }
    }

    &::after {
        content: "";
        position: absolute;
        height: 14px;
        width: 14px;
        background: var(--slate-2);
        top: 6px;
        left: 0;
        border-radius: 50%;
    }

    &.active {
        &::after {
            border: 3px solid var(--color-primary);
            background-color: var(--white);
        }
    }

    &.complete {
        .complete-tick {
            display: contents;
        }

        .complete-tick span {
            line-height: 1;
            position: absolute;
            left: -3px;
            top: 4px;
            font-size: 20px;
            color: var(--plum-7) !important;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: var(--white);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }
    }
}

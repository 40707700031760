.img {
  &-h-25 {
    height: 25px;
  }

  &-h-35 {
    height: 35px;
  }

  &-w-25 {
    width: 25px;
  }
}

.image-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  padding-bottom: 12px;

  .uploaded-image-wrap {
    position: relative;
  }

  .uploaded-img {
    img {
      width: 70px;
      height: 70px;
      border-radius: 5px;
      margin-bottom: 8px;
      margin-right: 8px;
      object-fit: cover;
    }

    &--thumbnail {
      img {
        position: relative;
        opacity: 0.5;
      }
    }
  }

  .img-delete-trigger {
    position: absolute;
    top: -4px;
    right: 0;
  }
}

.location-img {
  height: 1.4em;
}

.cdk-drag-preview .uploaded-img img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
}
.cdk-drag-preview {
  button {
    display: none;
  }
}

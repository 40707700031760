.steps-progress {
  display: flex;
  gap: 24px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.steps-count {
  display: flex;
  gap: 16px;

  & > div {
    display: flex;
    align-items: center;
    position: relative;

    &.completed {
      .desc {
        opacity: 0.22;
      }
    }

    &:not(:last-child) {
      padding-right: 16px;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid var(--slate-1);
      }
    }
  }

  .number {
    width: 20px;
    min-width: 20px;
    height: 20px;
    background: var(--color-secondary);
    border-radius: 100px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }


  @media (max-width: 767px) {
    gap: 24px;
    flex-direction: column;

    & > div {

      &:not(:last-child) {
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

.print-only {
  display: none !important;
}

.to-print {
  .print-d-none,
  ngx-skeleton-loader {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  .print-border-none {
    border: none;
  }

  .page-break-inside-avoid {
    break-inside: avoid;
  }

  .page-break-before {
    page-break-before: always;
  }

  .page-break-after {
    page-break-after: always;
  }
}

@media print {
  #userflow-ui {
    display: none;
  }
}

@use "sass:math";
@use "./mixins" as *;

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-light-color);
}

*,
html,
body {
  font-family: var(--font-family);
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
}

// Dimensions
@for $i from 1 through 20 {
  .h-#{$i * 5} {
    height: percentage(math.div($i, 20)) !important;
  }
}

@for $i from 1 through 20 {
  .w-#{$i * 5} {
    width: percentage(math.div($i, 20)) !important;
  }
}

$spaceamounts: (0, 4, 8, 16, 28, 48);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  $i: index($spaceamounts, $space);
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i - 1}:not([felloTheme] *) {
      margin-#{$side}: #{$space}px !important;
    }

    .m#{str-slice($side, 0, 1)}-#{$i - 1}-ni:not([felloTheme] *) {
      margin-#{$side}: #{$space}px;
    }

    .p#{str-slice($side, 0, 1)}-#{$i - 1}:not([felloTheme] *) {
      padding-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$i - 1}-ni:not([felloTheme] *) {
      padding-#{$side}: #{$space}px !important;
    }
  }

  .mx-#{$i - 1}:not([felloTheme] *) {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }
  .my-#{$i - 1}:not([felloTheme] *) {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }

  .p-#{$i - 1}:not([felloTheme] *) {
    padding: #{$space}px !important;
  }
  .p-#{$i - 1}-ni:not([felloTheme] *) {
    padding: #{$space}px !important;
  }
}

.m-0:not([felloTheme] *) {
  margin: 0 !important;
}

.m-0-ni {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-auto:not([felloTheme] *) {
  margin: auto !important;
}

.mt-auto:not([felloTheme] *) {
  margin-top: auto !important;
}

.my-auto:not([felloTheme] *) {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mr-auto:not([felloTheme] *) {
  margin-right: auto !important;
}

.mx-auto:not([felloTheme] *) {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mb-auto:not([felloTheme] *) {
  margin-bottom: auto !important;
}

.ml-auto:not([felloTheme] *) {
  margin-left: auto !important;
}

@media (min-width: 992px) {
  .ml-lg-auto {
    margin-left: auto !important;
  }
}

.p-0:not([felloTheme] *) {
  padding: 0 !important;
}

.p-3:not([felloTheme] *) {
  padding: 16px !important;
}

.py-0:not([felloTheme] *) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1:not([felloTheme] *) {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2:not([felloTheme] *) {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3:not([felloTheme] *) {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-4:not([felloTheme] *) {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-5:not([felloTheme] *) {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.px-2:not([felloTheme] *) {
  padding-left: 8px;
  padding-right: 8px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.p-12 {
  padding: 12px !important;
}

//Display
.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-contents {
  display: contents !important;
}

@media (min-width: 991px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-table {
    display: table !important;
  }
}

@media (max-width: 991px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .mw-sm-100 {
    max-width: 100% !important;
  }
  .mt-sm-2 {
    margin-top: 8px !important;
  }
  .mb-sm-2 {
    margin-bottom: 8px !important;
  }
  .mb-sm-3 {
    margin-bottom: 16px !important;
  }
  .mb-sm-4 {
    margin-bottom: 28px !important;
  }
}

@media (max-width: 767px) {
  .d-sm-none {
    display: none !important;
  }
}

[hidden] {
  visibility: hidden !important;
}

//Flex
.flex-box {
  display: flex !important;

  &-center {
    display: flex !important;
    align-items: center !important;
  }

  &-baseline {
    display: flex !important;
    align-items: baseline !important;
  }

  &-align-end {
    display: flex !important;
    align-items: flex-end !important;
  }

  @media (min-width: 992px) {
    &-lg-center {
      display: flex !important;
      align-items: center !important;
    }
  }

  &-all-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  @media (min-width: 1200px) {
    &-xl-all-center {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }

  &-justify-center {
    display: flex !important;
    justify-content: center !important;
  }

  @media (min-width: 992px) {
    &-lg-justify-center {
      display: flex !important;
      justify-content: center !important;
    }
  }

  &-justify-flex-start {
    display: flex !important;
    justify-content: flex-start !important;
  }

  @media (min-width: 992px) {
    &-lg-justify-flex-start {
      display: flex !important;
      justify-content: flex-start !important;
    }
  }

  &-between {
    display: flex !important;
    justify-content: space-between !important;
  }

  @media (min-width: 992px) {
    &-lg-between {
      display: flex !important;
      justify-content: space-between !important;
    }
  }

  &-end {
    display: flex !important;
    justify-content: flex-end !important;
  }

  @media (min-width: 992px) {
    &-lg-end {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }

  &-center-end {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  @media (min-width: 992px) {
    &-lg-center-end {
      display: flex !important;
      justify-content: flex-end !important;
      align-items: center !important;
    }
  }

  &-align-start {
    display: flex !important;
    align-items: flex-start !important;
  }

  @media (min-width: 992px) {
    &-lg-align-start {
      display: flex !important;
      align-items: flex-start !important;
    }
  }

  &-align-end {
    display: flex !important;
    align-items: flex-end !important;
  }

  @media (min-width: 992px) {
    &-lg-align-end {
      display: flex !important;
      align-items: flex-end !important;
    }
  }

  &-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  @media (min-width: 767px) {
    &-md-center-between {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }
  }

  @media (max-width: 991px) {
    &-md-center-between {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }
  }

  @media (min-width: 992px) {
    &-lg-center-between {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }
    &-lg-top-between {
      display: flex !important;
      align-items: baseline !important;
      justify-content: space-between !important;
    }
  }

  @media (min-width: 1300px) {
    &-xl-center-between {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }
  }

  &-baseline-between {
    display: flex !important;
    align-items: baseline !important;
    justify-content: space-between !important;
  }

  @media (min-width: 992px) {
    &-lg-baseline-between {
      display: flex !important;
      align-items: baseline !important;
      justify-content: space-between !important;
    }
  }

  &-around {
    display: flex !important;
    justify-content: space-around !important;
  }

  &-center-around {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }

  &-top-between {
    display: flex !important;
    align-items: baseline !important;
    justify-content: space-between !important;
  }

  &-end {
    display: flex !important;
    justify-content: flex-end !important;
  }

  &-direction-column {
    display: flex !important;
    flex-direction: column !important;
  }

  &-direction-column-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  &-direction-row {
    display: flex !important;
    flex-direction: row !important;
  }

  @media (min-width: 992px) {
    &-lg-direction-row {
      display: flex !important;
      flex-direction: row !important;
    }
  }

  &-direction-row-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
  }

  @media (min-width: 992px) {
    &-lg-direction-column {
      display: flex !important;
      flex-direction: column !important;
    }
  }

  &-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  @for $i from 1 through 5 {
    &-gap-#{$i} {
      gap: (math.div($i, 2)) + em;
    }
  }
}

@media (min-width: 992px) {
  .flex-lg-box {
    display: flex !important;
  }
  .flex-box-lg-all-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.flex-direction {
  &-row {
    flex-direction: row !important;
  }

  &-column {
    flex-direction: column !important;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}

@media (min-width: 767px) {
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media (min-width: 992px) {
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media (min-width: 1300px) {
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media (max-width: 767px) {
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

.flex-item-fixed-sizing {
  min-height: 0;
  min-width: 0;
  flex: 0 0 auto;
}

.flex-item-auto-sizing {
  min-height: 0;
  min-width: 0;
  flex: 1 1 auto;
}

@media (min-width: 767px) {
  .flex-lg-item-auto-sizing {
    min-height: 0;
    min-width: 0;
    flex: 1 1 auto;
  }
}

.flex-item-fixed-max-content {
  flex: 0 0 max-content;
}

@media (min-width: 767px) {
  .flex-lg-box-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 767px) {
  .flex-lg-box-top-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

//Object fit
.object-fit {
  &-contain {
    object-fit: contain !important;
  }

  &-cover {
    object-fit: cover !important;
  }
}

//Position
.position {
  &-relative {
    position: relative !important;
  }

  &-absolute {
    position: absolute !important;
  }

  @media (min-width: 992px) {
    &-lg-absolute {
      position: absolute !important;
    }
  }
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// float
.float-right {
  float: right;
}

.float-left {
  float: left;
}

//Overflow
.overflow {
  &-hidden {
    overflow: hidden !important;
  }

  &-visible {
    overflow: visible !important;
  }

  &-auto {
    overflow: auto !important;
  }

  @media (min-width: 992px) {
    &-lg-auto {
      overflow: auto !important;
    }
  }

  &-y-auto {
    overflow-y: auto !important;
  }

  &-x-auto {
    overflow-x: auto !important;
  }
}

.mandatory-x-scroll-snapping {
  scroll-snap-type: x mandatory;
}

.scroll-snap-align-start {
  scroll-snap-align: start;
}

.w-auto {
  width: auto !important;
}

.mw-160 {
  min-width: 160px;
  max-width: 160px;
}

.mw-40 {
  max-width: 40px;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px;
}

.w-300 {
  width: 300px;
}

.w-350 {
  width: 350px;
}

.w-350-imp {
  width: 350px !important;
}

.w-450 {
  width: 450px;
}

.w-580 {
  width: 580px;
}

.w-fit-content {
  width: fit-content !important;
}

.h-auto {
  height: auto !important;
}

.h-fit-content {
  height: fit-content !important;
}

.h-90px {
  height: 90px;
}

.h-250 {
  height: 250px;
}

.h-350px {
  height: 350px;
}

.h-180px {
  height: 180px;
}

.h-10px {
  height: 10px !important;
}

.h-30px {
  height: 30px !important;
}

.h-60px {
  height: 60px !important;
}

.h-15px {
  height: 15px;
}

.h-25px {
  height: 25px;
}

//Curser
.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.filter-blur-3 {
  filter: blur(3px);
}

.filter-blur-4 {
  filter: blur(4px);
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

//Borders
$widthamounts: (1, 2);
$sides: (top, bottom, left, right);

@each $width in $widthamounts {
  $i: index($widthamounts, $width);
  @each $side in $sides {
    .border-#{$side}-#{$i} {
      border-#{$side}: #{$width}px solid var(--slate-2) !important;
    }
  }
}

.border-none {
  border: none !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.progress-circular {
  display: block;
  margin: 0;
  height: 25px;
  width: 25px;

  .circle {
    stroke: var(--color-primary);
    fill: none;
    stroke-width: 4;
    //animation: progress 1s ease-out forwards;
  }

  .outer-circle {
    stroke: var(--slate-1);
    fill: none;
    stroke-width: 4;
    //animation: progress 1s ease-out forwards;
  }
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.dummy-data {
  display: none;
}

.dummy-view {
  .actual-data {
    display: none;
  }

  .dummy-data {
    display: block;
  }
}

.mw-700 {
  min-width: 775px;
}

.mw-720 {
  min-width: 720px;
}

.mw-16 {
  min-width: 16px;
}

.max-height-none {
  max-height: none !important;
}

.max-height-480 {
  max-height: 480px !important;
}

.max-width-180 {
  max-width: 180px;
}

.max-width-220 {
  max-width: 220px;
}

.max-width-220-imp {
  max-width: 220px !important;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-300-imp {
  max-width: 300px !important;
}

.max-width-390 {
  max-width: 390px;
}

.max-width-390-imp {
  max-width: 390px !important;
}

.max-width-550 {
  max-width: 550px;
}

.container-type-inline {
  container-type: inline-size;
}

.container-type-size {
  container-type: size;
}

.visible-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.badge {
  background-color: var(--red);
  border-radius: 100px;
  padding: 4px;
  color: var(--white);
  min-width: 20px;
  text-align: center;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.box-shadow {
  box-shadow: var(--box-shadow) !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.box-shadow-top {
  box-shadow: 0px -4px 10px 0px rgba(178, 177, 187, 0.15);
}

ul.ul-disc {
  list-style: disc;
  padding-left: 15px;

  li {
    list-style: disc;
  }
}

.border-1 {
  border-radius: var(--border-radius);
  padding: 8px;
  border: 1px solid var(--slate-2);
}

.rounded {
  --border-radius: 8px;
}

.semi-rounded {
  --border-radius: 4px;
}

.squared {
  --border-radius: 0;
}

.fully-rounded {
  --border-radius: 40px;
}

.yellow-banner {
  background: var(--yellow-5);
  padding: 8px 24px;
}

.border-color {
  @include border-color($prefix: "-");
}

.no-border {
  border: none !important;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.ai-gradient {
  background: linear-gradient(75.05deg, #0dc1c4 9.38%, #3d93f5 29.11%, #8d6ae7 48.84%, #eb61a2 68.57%, #f79e91 88.31%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ai-gradient-border {
  border: 2px solid;
  border-image: linear-gradient(75.05deg, #0dc1c4 9.38%, #3d93f5 29.11%, #8d6ae7 48.84%, #eb61a2 68.57%, #f79e91 88.31%);
  border-image-slice: 1;
}

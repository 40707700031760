button,
.button {
  &:disabled {
    display: initial;
  }
}

.card-collapse-button {
  border-radius: var(--border-radius);
}

@use "./mixins" as *;

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.text-italic {
  font-style: italic !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-sentence-case::first-letter {
  text-transform: uppercase !important;
}

.text-lower-sentence-case::first-letter {
  text-transform: lowercase !important;
}

.text-underline {
  text-decoration: underline !important;
  text-underline-offset: 1px;
}

.hover-underline:hover {
  text-decoration: underline;
}

.hover-underline-container:hover {
  .hover-underline-item {
    text-decoration: underline;
  }
}

.hover-no-underline:hover {
  text-decoration: none !important;
}

.hover-background {
  &:not(:hover) {
    background-color: transparent !important;
  }
}

.hover-opacity-1 {
  opacity: 0 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.text-right {
  text-align: right !important;
}

.hover-container {
  .hover-show {
    opacity: 0;
  }

  &:hover {
    .hover-show {
      opacity: 1;
    }
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

.vertical-middle {
  vertical-align: middle !important;
}

.text-color-primary {
  color: var(--color-primary) !important;
}

.text-color-secondary {
  color: var(--color-secondary) !important;
}

.text-color-light-2 {
  color: var(--text-color-light-2);
}

.text-color-facebook {
  color: #1877f2 !important;
}

.text-color-twitter {
  color: #1da1f2 !important;
}

.text-color-linkedin {
  color: #0077b5 !important;
}

.text-color-whatsapp {
  color: #25d366 !important;
}

.text-color-instagram {
  background: linear-gradient(0deg, #dc8d40 0.47%, #c3256c 57.42%, #7423c0 110.91%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.text-transform-none {
  text-transform: none !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.word-break {
  white-space: normal;
  word-break: break-word;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-1-2 {
  line-height: 1.2 !important;
}

.line-height-1_5 {
  line-height: 1.5 !important;
}

.line-height-2 {
  line-height: 2 !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

$fontsizes: (8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 25, 26, 28, 30, 32, 35, 36, 40, 44, 45, 50, 55, 60);

@each $fontsize in $fontsizes {
  .text-size-#{$fontsize} {
    font-size: #{$fontsize}px !important;
  }
}

.text-color {
  @include color-modifiers($prefix: "-");
}

.bg {
  @include color-modifiers($attribute: "background-color", $prefix: "-");
}

.bg-transparent {
  background: transparent !important;
}

.bg-gradient-ai {
  background: linear-gradient(98deg, #e7f1fe 3.79%, #f2edfc 35.43%, #fff2f3 67.07%, #fde7e3 98.71%);
}

.text-color-light {
  color: var(--plum-4);
}

$fontweights: (100, 300, 400, 500, 600, 700, 800);

@each $weight in $fontweights {
  .fw-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

$opacities: (0, 25, 50, 75, 100);

@each $opacity in $opacities {
  .opacity-#{$opacity} {
    opacity: calc(#{$opacity} / 100) !important;
  }

  @media (min-width: 992px) {
    .opacity-lg-#{$opacity} {
      opacity: calc(#{$opacity} / 100) !important;
    }
  }
}

.filter-grayscale-0 {
  filter: grayscale(0);
}

.filter-grayscale-1 {
  filter: grayscale(1);
}

.font-courier {
  font-family: "Courier";
}

.error-field {
  color: var(--red);
  font-size: 12px;
}

.search-highlight {
  background: var(--yellow-3);
}

.link-underline {
  a {
    text-decoration: underline;
    text-underline-offset: 1px;
  }
}

// Color Variables
$color-salmon: #f58675;
$color-salmon-dark: #e15e4a;
$color-salmon-rgb: 245, 134, 117;
$color-plum: #3f3d56;
$color-plum-rgb: 63, 61, 86;
$color-plum-light: #46445e;
$color-plum-dark: #323145;
$color-plum-dark-rgb: 52, 50, 71;
$color-plum-000: #fafafa;
$color-plum-00: #ececee;
$color-plum-1: #d9d8dd;
$color-plum-2: #b2b1bb;
$color-plum-3: #8c8b9a;
$color-plum-4: #656478;
$color-plum-5: #3f3d56;
$color-plum-7: #262534;
$color-white: #ffffff;
$color-dark-grey: #717782;
$color-dark-grey-rgb: 113, 119, 130;
$color-light-grey: #f2f4f7;
$color-light-grey-rgb: 242, 244, 247;
$color-border-light-grey: #e4e8ef;
$color-border-light-grey-rgb: 228, 232, 239;
$color-blue-000: #f4faff;
$color-blue-00: #e7f3ff;
$color-blue: #0869c9;
$color-blue-1: #cee1f4;
$color-blue-2: #9cc3e9;
$color-blue-4: #3987d4;
$color-blue-6: #0654a1;
$color-blue-7: #053f79;
$color-black: #000000;
$color-red-00: #ffece8;
$color-red-2: #ffb4a4;
$color-red-4: #ff6949;
$color-red-6: #cc3616;
$color-red-light: #fbdcd5;
$color-red-light-background: rgba(251, 220, 213, 0.5);
$color-red: #ff431b;
$color-red-1: #ffd9d1;
$color-red-7: #992810;
$color-red-dark: #8c2f1b;
$color-yellow: #fee8ad;
$color-yellow-00: #fffaea;
$color-yellow-1: #fef4d6;
$color-yellow-3: #fddd84;
$color-yellow-4: #fdd15b;
$color-yellow-5: #fcc632;
$color-yellow-6: #ca9e28;
$color-yellow-7: #97771e;
$color-orange: #f38e27;
$color-orange-00: #fff5eb;
$color-orange-1: #fde8d4;
$color-orange-4: #f5a552;
$color-orange-6: #c2721f;
$color-orange-7: #925517;
$color-green-00: #eef6eb;
$color-green-2: #bbdcb0;
$color-green-4: #77b961;
$color-green-6: #44862e;
$color-green-7: #336523;
$color-green-000: #f9fef7;
$color-green-light: #ddeed8;
$color-green: #55a83a;
$color-green-dark: #44862e;
$color-slate-0: #f5f5f7;
$color-slate-00: #f5f5f7;
$color-slate-000: #fafafb;
$color-slate-1: #eaecef;
$color-slate-2: #d5d8de;
$color-slate-3: #c9d1e0;
$color-slate-4: #abb1bd;
$color-slate-5: #969ead;
$color-slate-6: #787e8a;
$color-slate-7: #5a5f68;
$color-slate-8: #3c3f45;
$color-coral-0: #fdf1ef;
$color-coral-00: #fdf1ef;
$color-coral-000: #fff9f8;
$color-coral-1: #fde7e3;
$color-coral-5: #f58675;
$color-coral-6: #c46b5e;
$color-coral-7: #935046;
$color-purple: #7f34e0;
$color-purple-1: #e5d6f9;

// Font Variables
$font-inter: "inter";
$font-size-14: "14px";
$line-height-1-5: "1.5";
$font-weight-500: "500";

//Dimensions
$border-radius-4: 4px;
$border-radius-8: 8px;
$border-radius-10: 10px;
$border-radius-100: 100px;
$box-shadow-1: 0 1.5px 3px rgba(86, 105, 125, 0.15);

$colors: (
  "primary": $color-salmon,
  "primary-rgb": $color-salmon-rgb,
  "secondary": $color-plum,
  "secondary-rgb": $color-plum-rgb,
  "salmon": $color-salmon,
  "salmon-dark": $color-salmon-dark,
  "salmon-rgb": $color-salmon-rgb,
  "plum": $color-plum,
  "plum-000": $color-plum-000,
  "plum-00": $color-plum-00,
  "plum-1": $color-plum-1,
  "plum-2": $color-plum-2,
  "plum-3": $color-plum-3,
  "plum-4": $color-plum-4,
  "plum-5": $color-plum-5,
  "plum-7": $color-plum-7,
  "plum-dark": $color-plum-dark,
  "plum-light": $color-plum-light,
  "plum-dark-rgb": $color-plum-dark-rgb,
  "white": $color-white,
  "yellow": $color-yellow,
  "yellow-1": $color-yellow-1,
  "yellow-4": $color-yellow-4,
  "yellow-5": $color-yellow-5,
  "yellow-6": $color-yellow-6,
  "yellow-7": $color-yellow-7,
  "yellow-00": $color-yellow-00,
  "yellow-3": $color-yellow-3,
  "dark-grey": $color-dark-grey,
  "dark-grey-rgb": $color-dark-grey-rgb,
  "light-grey": $color-light-grey,
  "light-grey-rgb": $color-light-grey-rgb,
  "border-light-grey": $color-border-light-grey,
  "border-light-grey-rgb": $color-border-light-grey-rgb,
  "coral-0": $color-coral-0,
  "coral-00": $color-coral-00,
  "coral-000": $color-coral-000,
  "coral-1": $color-coral-1,
  "coral-5": $color-coral-5,
  "coral-6": $color-coral-6,
  "coral-7": $color-coral-7,
  "purple": $color-purple,
  "purple-1": $color-purple-1,
  "blue": $color-blue,
  "blue-00": $color-blue-00,
  "blue-000": $color-blue-000,
  "blue-1": $color-blue-1,
  "blue-2": $color-blue-2,
  "blue-4": $color-blue-4,
  "blue-6": $color-blue-6,
  "blue-7": $color-blue-7,
  "black": $color-black,
  "red": $color-red,
  "red-00": $color-red-00,
  "red-2": $color-red-2,
  "red-4": $color-red-4,
  "red-6": $color-red-6,
  "red-1": $color-red-1,
  "red-7": $color-red-7,
  "red-light": $color-red-light,
  "red-light-background": $color-red-light-background,
  "red-dark": $color-red-dark,
  "orange": $color-orange,
  "orange-00": $color-orange-00,
  "orange-1": $color-orange-1,
  "orange-4": $color-orange-4,
  "orange-6": $color-orange-6,
  "orange-7": $color-orange-7,
  "green-00": $color-green-00,
  "green-2": $color-green-2,
  "green-4": $color-green-4,
  "green-6": $color-green-6,
  "green-7": $color-green-7,
  "green-000": $color-green-000,
  "green-light": $color-green-light,
  "green": $color-green,
  "green-dark": $color-green-dark,
  "slate-0": $color-slate-0,
  "slate-00": $color-slate-00,
  "slate-000": $color-slate-000,
  "slate-1": $color-slate-1,
  "slate-2": $color-slate-2,
  "slate-3": $color-slate-3,
  "slate-4": $color-slate-4,
  "slate-5": $color-slate-5,
  "slate-8": $color-slate-8,
  "slate-7": $color-slate-7,
  "slate-6": $color-slate-6
);

$variables: (
  "primary": $color-salmon,
  "primary-rgb": $color-salmon-rgb,
  "secondary": $color-plum,
  "secondary-rgb": $color-plum-rgb,
  "salmon": $color-salmon,
  "salmon-dark": $color-salmon-dark,
  "salmon-rgb": $color-salmon-rgb,
  "plum": $color-plum,
  "plum-000": $color-plum-000,
  "plum-00": $color-plum-00,
  "plum-1": $color-plum-1,
  "plum-2": $color-plum-2,
  "plum-3": $color-plum-3,
  "plum-4": $color-plum-4,
  "plum-5": $color-plum-5,
  "plum-7": $color-plum-7,
  "plum-dark": $color-plum-dark,
  "plum-light": $color-plum-light,
  "plum-dark-rgb": $color-plum-dark-rgb,
  "white": $color-white,
  "yellow": $color-yellow,
  "yellow-1": $color-yellow-1,
  "yellow-4": $color-yellow-4,
  "yellow-5": $color-yellow-5,
  "yellow-6": $color-yellow-6,
  "yellow-7": $color-yellow-7,
  "yellow-00": $color-yellow-00,
  "yellow-3": $color-yellow-3,
  "dark-grey": $color-dark-grey,
  "dark-grey-rgb": $color-dark-grey-rgb,
  "light-grey": $color-light-grey,
  "light-grey-rgb": $color-light-grey-rgb,
  "border-light-grey": $color-border-light-grey,
  "border-light-grey-rgb": $color-border-light-grey-rgb,
  "blue": $color-blue,
  "blue-00": $color-blue-00,
  "blue-000": $color-blue-000,
  "blue-1": $color-blue-1,
  "blue-2": $color-blue-2,
  "blue-4": $color-blue-4,
  "blue-6": $color-blue-6,
  "blue-7": $color-blue-7,
  "black": $color-black,
  "red": $color-red,
  "red-00": $color-red-00,
  "red-2": $color-red-2,
  "red-4": $color-red-4,
  "red-6": $color-red-6,
  "red-1": $color-red-1,
  "red-7": $color-red-7,
  "red-light": $color-red-light,
  "red-light-background": $color-red-light-background,
  "red-dark": $color-red-dark,
  "orange": $color-orange,
  "orange-00": $color-orange-00,
  "orange-1": $color-orange-1,
  "orange-4": $color-orange-4,
  "orange-6": $color-orange-6,
  "orange-7": $color-orange-7,
  "green-7": $color-green-7,
  "green-00": $color-green-00,
  "green-000": $color-green-000,
  "green-2": $color-green-2,
  "green-4": $color-green-4,
  "green-6": $color-green-6,
  "green-light": $color-green-light,
  "green": $color-green,
  "green-dark": $color-green-dark,
  "slate-0": $color-slate-0,
  "slate-00": $color-slate-00,
  "slate-000": $color-slate-000,
  "slate-1": $color-slate-1,
  "slate-2": $color-slate-2,
  "slate-3": $color-slate-3,
  "slate-4": $color-slate-4,
  "slate-5": $color-slate-5,
  "slate-6": $color-slate-6,
  "slate-7": $color-slate-7,
  "slate-8": $color-slate-8,
  "coral-0": $color-coral-0,
  "coral-00": $color-coral-00,
  "coral-000": $color-coral-000,
  "coral-1": $color-coral-1,
  "coral-5": $color-coral-5,
  "coral-6": $color-coral-6,
  "coral-7": $color-coral-7,
  "purple": $color-purple,
  "purple-1": $color-purple-1,
  "inter": $font-inter,
  "font-size-14": $font-size-14,
  "line-height-1-5": $line-height-1-5,
  "font-weight-500": $font-weight-500,
  "border-radius-4": $border-radius-4,
  "border-radius-8": $border-radius-8,
  "border-radius-10": $border-radius-10,
  "border-radius-100": $border-radius-100,
  "box-shadow-1": $box-shadow-1
);

// CSS Variables
:root {
  $prefix: "--";
  @each $name, $value in $variables {
    #{$prefix}#{$name}: #{$value};
  }
}

mat-chip.mat-chip {
  border-radius: 4px;
  padding: 3.5px 3.5px 3.5px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.5px;
  background: var(--plum-1);

  .mat-chip-remove.mat-chip-remove {
    opacity: 1;
  }
}

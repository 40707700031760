@keyframes dot-flashing {
  0% {
    opacity: 0.4;
    transform: scale(0.6);
  }

  50% {
    opacity: 0.7;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
  }
}

.animate__slideInBottom {
  animation-name: slideInBottom;
  animation-duration: 0.2s;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
  }
}

@keyframes revealContent {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
  animation-duration: 0.2s;
}

@keyframes bounce {
  0% {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

.animate__bounce {
  animation-name: bounce;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  transform-origin: center;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.animate__spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

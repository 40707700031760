.propensity-score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border-radius: 100px;
  background: var(--slate-5);
  box-shadow: 0 1.5px 3px 0 rgba(86, 105, 125, 0.15);
  padding: 4px 6px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  color: var(--white) !important;
  min-width: 32px;

  &.ps-very-high {
    background: var(--green-6);
  }

  &.ps-high {
    background: var(--green-4);
  }

  &.ps-moderate {
    background: var(--yellow-6);
  }

  &.ps-low {
    background: var(--orange-6);
  }

  &.ps-very-low {
    background: var(--red-6);
  }
}

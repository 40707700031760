.ngx-spinner-overlay {
  & > * {
    display: none;
  }
}

.black-loader {
  .loader.progress:before {
    background: rgba(0, 0, 0, 0.6) !important;
  }
}

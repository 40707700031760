.line-chart-defaults {
  .ngx-charts {
    text {
      fill: var(--text-color) !important;
    }

    .gridline-path {
      stroke: var(--slate-0);
    }
  }

  .ngx-charts {
    position: relative;
    margin-left: -2%;
    border: transparent;

    .tooltip-anchor {
      fill: var(--text-color) !important;
    }
  }

  [ngx-charts-line] .line {
    stroke-width: 2px;
  }

  [ngx-charts-x-axis-ticks] {
    > g {
      ~ g {
        display: none;
      }
    }
  }
}

.content-performance-chart {
  &:not(lib-fello-combo-chart) {
    .ngx-charts {
      margin-left: -5px;
    }
  }

  .ngx-charts {
    position: relative;
    border: transparent;
    margin-left: -10px;

    text {
      fill: var(--text-color) !important;
    }

    [ngx-charts-y-axis-ticks] {
      g:nth-child(2) {
        .gridline-path {
          stroke: var(--slate-3);
        }
      }
    }

    .gridline-path {
      stroke: var(--slate-0);
    }

    .tooltip-anchor {
      fill: var(--text-color) !important;
    }
  }

  [ngx-charts-line] .line {
    stroke-width: 2px;
  }

  &:not(.bar-stacked-horizontal) {
    [ngx-charts-x-axis-ticks] {
      > g {
        ~ g {
          display: none;
        }
      }
    }
  }

  .axis {
    &.x {
      text {
        font-size: 8px;
      }
    }
  }
}

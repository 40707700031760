.radio-option {
  margin-bottom: 16px;

  input:not([felloTheme] *) {
    display: none;
  }

  label {
    display: flex;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--white);
    background-color: var(--plum-light);
    transition: all ease 0.3s;
    align-items: center;
    cursor: pointer;
    padding: 22px 16px;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    img {
      height: 55px;
      width: 55px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  input:checked + label,
  label:hover {
    background-color: var(--color-primary);
  }

  input:disabled + label {
    opacity: 0.5;
    cursor: none;
    background-color: var(--plum-light);
  }
}

.radio-option-arrow {
  margin-bottom: 16px;

  input:not([felloTheme] *) {
    display: none;
  }

  label {
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--text-color);
    background-color: var(--background-light-color);
    transition: all ease 0.3s;
    align-items: center;
    cursor: pointer;
    padding: 16px;
    border-radius: var(--border-radius);
    overflow: hidden;
    border: 1px solid var(--border-color);

    p {
      transition: all ease 0.3s;
    }
  }

  input:checked + label,
  label:hover {
    background-color: var(--color-primary);
    color: var(--white);

    app-icon span {
      color: var(--white) !important;
    }
  }

  input:checked + label,
  input:not(:disabled):hover + label {
    p {
      color: var(--white) !important;
    }
  }

  input:disabled + label,
  input:disabled + label:hover {
    opacity: 0.5;
    cursor: default;
    background-color: var(--background-light-color);
    color: var(--text-color);

    app-icon span {
      color: var(--color-primary) !important;
    }
  }

  &.condition {
    label {
      padding: 14px 16px;
      font-size: 16px;
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.radio-option-circle {
  margin-bottom: 16px;

  & > label {
    background: var(--white);
    border: 1px solid var(--slate-2);
    padding: 12px 16px 12px 44px;
    border-radius: var(--border-radius);
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    font-weight: 600;

    &:before {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: var(--white);
      border: 1px solid var(--slate-2);
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease 0.3s;
    }
  }

  input:not([felloTheme] *) {
    display: none;

    &:checked + label {
      &:before {
        border: 5px solid var(--color-primary);
      }
    }

    &:not(.fake-disabled) {
      &:disabled + label {
        &:before {
          filter: grayscale(1);
          opacity: 0.8;
        }
      }
    }
  }

  &.no-box {
    & > label {
      padding: 0 0 0 24px;
      font-size: 14px;
      font-weight: 400;
      border: none;

      &:before {
        left: 0;
      }
    }
  }

  &.dot-top {
    & > label:before {
      top: 2px;
      transform: none;
    }
  }
}

.radio-fill {
  width: 100%;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: var(--white);
    border: 1px solid var(--color-primary);
    border-radius: var(--border-radius);
    font-weight: 700;
    font-size: 16px;
    transition: all ease 0.3s;
    cursor: pointer;
    color: var(--color-secondary);
  }

  input:not([felloTheme] *) {
    display: none;

    &:checked + label {
      background: var(--color-secondary);
      border: 1px solid transparent;
      color: var(--white);
    }
  }
}

.radio-box-c {
  display: flex;
  justify-content: center;
}

.radio-box {
  input:not([felloTheme] *) {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    background-color: var(--white);
    padding: 7px 16px;
    transition: all ease 0.3s;
    cursor: pointer;
    margin: 0;
  }

  &:first-child {
    label {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
  }

  &:last-child {
    label {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  input:checked + label {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
  }
}

.radio-filters {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .filter {
    input {
      display: none;
    }

    label {
      cursor: pointer;
      font-size: 12px;
      line-height: 15px;
      padding: 8px;
      border-radius: 4px;
      margin: 0;
      font-weight: 500;
      display: flex;
      align-items: center;
      transition: all ease 0.3s;
      border: 1px solid transparent;

      a {
        display: none;
      }

      &:hover a,
      &.active a {
        display: block;
      }
    }

    input:checked + label,
    label:hover,
    label.active {
      background-color: var(--coral-000);
      color: var(--coral-6);
      border: 1px solid var(--color-primary);
    }

    input:checked + label,
    label.active {
      font-weight: 600;
    }
  }
}

.mat-radio-group {
  .mat-radio-button {
    &.mat-radio-disabled {
      .mat-radio-outer-circle {
        background-color: var(--slate-0);
      }
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        background: var(--primary);
        border-color: var(--primary);
      }

      .mat-radio-inner-circle {
        background: var(--white);
      }

      &.mat-radio-disabled {
        .mat-radio-outer-circle {
          background-color: var(--slate-5);
          border-color: var(--slate-5);
        }
      }
    }

    .mat-radio-label-content {
      font-weight: 400;
    }
  }
}

.radio-group-panel {
  display: flex;

  .radio-panel {
    font-size: 12px;
    font-weight: 600;
    padding: 5px 12px;
    color: var(--plum-4);
    border: 1px solid var(--slate-2);
    white-space: nowrap;
    margin: 0;

    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }

    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }

    input {
      display: none;
    }

    &:has(input:checked) {
      color: var(--color-primary);
      background-color: var(--coral-000);
      border-color: var(--color-primary);
    }
  }

  &.grey {
    .radio-panel {
      color: var(--plum);
      border: 1px solid var(--slate-5);
      background-color: var(--slate-0);

      &:has(input:checked) {
        color: var(--plum);
        background-color: var(--slate-1);
        border-color: var(--slate-5);
      }
    }
  }
}

.radio-draggable-filters {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.radio-draggable-filter {
  border-radius: 4px;
  padding: 6px 6px 6px 20px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  height: 32px;

  input {
    display: none;
  }

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    min-height: 0;
    min-width: 0;
    flex: 1 1 auto;
    color: var(--color-secondary);
    transition: all ease 0.3s;
  }

  .drag-icon {
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
    cursor: grab;
    opacity: 0;
    transition: all ease 0.3s;
  }

  .hover-show {
    opacity: 0;
    transition: all ease 0.3s;
  }

  &:not(:hover) {
    .hover-display {
      display: none;
    }
  }

  &:hover {
    background-color: var(--slate-1);

    .hover-display-none {
      display: none;
    }

    .hover-show,
    .drag-icon {
      opacity: 1;
    }
  }

  input:checked + label,
  label.active {
    color: var(--color-primary);
    font-weight: 600;
  }

  &.cdk-drag-dragging,
  &.cdk-drag-preview {
    pointer-events: auto !important;
    background-color: var(--slate-00) !important;

    .drag-icon {
      cursor: grabbing !important;
    }
  }
}

.radio-card-c {
  display: flex;
  gap: 8px;

  &:has(.radio-card--selected) {
    .radio-card {
      padding: 2px;
      padding-top: 24px;
    }
  }
}

.radio-card {
  position: relative;
  border-radius: 10px;
  flex: 1;

  &__content {
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    padding: 12px 16px 16px;
    background-color: var(--white);
    border-radius: 8px;
    height: 100%;
  }

  &__selected-text {
    position: absolute;
    top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--white);
  }

  &--selected {
    background: var(--plum);
    padding: 2px;
    padding-top: 24px;

    .radio-card__content {
      border-color: transparent;
    }
  }
}

.gm-style-iw {
  //background-color: var(--slate-8) !important;
  padding: 0 !important;
  border-radius: 4px !important;

  .gm-style-iw-d {
    overflow: auto !important;
  }

  & > button {
    display: none !important;
  }
}


.gm-style .gm-style-iw-tc::after {
  background: var(--slate-8);
}

@use "./variables" as *;

@mixin color-modifiers($attribute: "color", $prefix: "-") {
  @each $name, $hex in $colors {
    &#{$prefix}#{$name} {
      #{$attribute}: var(--#{$name}) !important;
    }
  }
}

@mixin tag-modifiers($attribute: "background-color", $prefix: "-") {
  @each $name, $hex in $colors {
    &#{$prefix}#{$name} {
      #{$attribute}: var(--#{$name}) !important;
    }
  }
}

@mixin border-color($attribute: "border-color", $prefix: "-") {
  @each $name, $hex in $colors {
    &#{$prefix}#{$name} {
      #{$attribute}: var(--#{$name}) !important;
    }
  }
}

@mixin border-generator($attribute: "border", $prefix: "-") {
  @each $name, $hex in $colors {
    &#{$prefix}#{$name} {
      #{$attribute}: 1px solid var(--#{$name}) !important;
    }
  }
}

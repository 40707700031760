.ratings-c {
    display: flex;

    .rating {
        height: 10px;
        width: 10px;
        min-width: 10px;
        border-radius: 50%;
        margin-right: 4px;
        background-color: var(--color-primary);
        opacity: 0.2;

        &.active {
            opacity: 1;
        }
    }
}

.confidence {
    display: flex;

    img {
        height: 30px;
        object-fit: contain;
        object-position: center;
        margin-right: 6px;
    }
}

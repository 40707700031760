.chat-answer {
  background-color: var(--color-primary);
  color: var(--white);
  font-size: 20px;
  line-height: 26px;
  border-radius: 35px;
  width: fit-content;
  margin-left: auto;
  padding: 15px 20px;
  position: relative;
  max-width: 90%;

  &::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    bottom: -2px;
    right: 0;
    border-radius: 50%;
    background: inherit;
  }
}

.chat-loader {
  display: flex;
  background-color: var(--color-secondary);
  padding: 20px;
  border-radius: var(--chat-border-radius);
  margin-top: 40px;
  width: fit-content;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    bottom: -2px;
    left: 0;
    border-radius: 50%;
    background: inherit;
  }

  span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--white);
    animation: dot-flashing 1s infinite linear alternate;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.8s;
    }
  }
}

.widget-app-container {
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .chat-bttn-adjustment {
    margin-right: 70px;
  }
}

.checkbox-option {
  input:not([felloTheme] *) {
    display: none;
  }

  label {
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--text-color);
    background-color: var(--background-light-color);
    transition: all ease 0.3s;
    align-items: center;
    cursor: pointer;
    padding: 18px 16px;
    border-radius: var(--border-radius);
    overflow: hidden;
    position: relative;
    border: 1px solid var(--border-color);

    .tick {
      height: 20px;
      width: 20px;
      border-radius: 5px;
      border: 1px solid var(--dark-grey);
      background-color: var(--white);
      color: var(--white);
      margin-right: 12px;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      transition: all ease 0.3s;
    }
  }

  input:checked + label {
    .tick {
      background-color: var(--color-primary);
      border: 1px solid var(--color-primary);
    }
  }
}

.mat-checkbox-inner-container {
  height: 18px !important;
  width: 18px !important;
}

.mat-checkbox-background {
  border-radius: 5px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
  background-color: var(--color-primary);
}

.mat-checkbox-frame {
  border-radius: 5px !important;
  background-color: var(--white) !important;
  border: 1px solid var(--slate-2);
}

.mat-checkbox-checkmark {
  transform: scale(0.8) !important;
}

.checkbox-icon .mat-checkbox-inner-container {
  display: none;
}
.mat-checkbox-layout {
  margin: 0;
}

.checkbox-custom {
  .mat-checkbox-inner-container {
    margin: 0 10px 0 0;
  }

  .mat-checkbox-layout {
    align-items: flex-start;
  }
}

.mat-ripple-element {
  display: none !important;
}

mat-checkbox.flex-box {
  .mat-checkbox-layout {
    display: flex;
    align-items: center;

    .mat-checkbox-label {
      line-height: 18px;
    }
  }

  &.align-start {
    .mat-checkbox-layout {
      align-items: flex-start;
    }

    .mat-checkbox-inner-container {
      margin-top: 0;
    }
  }
}

mat-checkbox {
  width: fit-content;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
  background-color: var(--color-primary);
}

.mat-checkbox-disabled {
  filter: grayscale(1);
}

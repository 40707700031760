@import "~quill/dist/quill.snow.css";
@import "~quill-mention/dist/quill.mention.css";

.ql-container {
  border: none !important;

  .ql-editor {
    padding: 10px 0px !important;
    word-break: break-word;
    border: none;
  }

  &:not(.ql-disabled) .ql-editor {
    padding: 4px 0 !important;
    word-break: break-word;
    min-height: 70px;
  }

  &.ql-snow + .ql-container.ql-snow .ql-editor {
    min-height: 60px;
    max-height: max(300px, 40vh);
    overflow-y: auto;
  }

  .mention {
    background-color: var(--blue-1) !important;
    color: var(--blue-7);
    border-radius: 100px;
    padding: 4px 8px;
  }

  .ql-mention-list-item.disabled {
    background-color: var(--slate-0);
    color: var(--plum-3);
  }
}

.ql-editor.ql-blank::before {
  left: auto;
  font-style: normal;
  right: auto;
  font-size: 14px;
  font-weight: 400;
  color: lightgrey;
}

.ql-mention-list-container {
  max-height: 200px;
  overflow-y: auto;
}

.ql-toolbar {
  border-radius: 5px;
  border: none !important;
  box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);

  &.ql-snow .ql-formats {
    margin-right: 0px;
  }
}

// Styles for Dynamic Tags Input Component
quill-editor.dynamic-tags-input {
  .ql-container {
    border: 1px solid var(--slate-2) !important;
    padding: 7px 12px;
    border-radius: 6px;
    background-color: var(--white);

    &:not(.ql-disabled) .ql-editor {
      padding: 0 !important;
      min-height: unset;
    }

    &.ql-snow + .ql-container.ql-snow .ql-editor {
      min-height: unset;
    }
  }

  .ql-editor {
    font-size: 14px;
    line-height: 24px;
    padding: 0 !important;

    &.ql-blank::before,
    &.ql-blank::after {
      color: var(--slate-2);
    }
  }

  .mention {
    border: 1px solid var(--slate-2) !important;
    background-color: var(--white) !important;
    color: var(--plum-5);
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    padding: 3px 1px;
  }

  .ql-mention-list-container {
    padding-top: 60px;
    max-height: 360px;
    padding: 2px 0;
    border-radius: 6px;
    border: 1px solid var(--slate-2);
    box-shadow: 0 4px 6px -2px rgba(27, 26, 39, 0.03), 0 12px 16px -4px rgba(27, 26, 39, 0.08);

    &::before {
      content: attr(data-heading string, "Input Field");
      display: block;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid var(--slate-2);
      margin-bottom: 2px;
    }

    &::after {
      content: attr(data-tag string, "Dynamic");
      position: absolute;
      top: 12px;
      left: 104px;
      padding: 1px 6px;
      font-size: 12px;
      font-weight: 500;
      background: var(--coral-1);
      color: var(--primary);
      border-radius: 4px;
    }
  }

  .ql-mention-list-item {
    border-radius: 4px;
    margin: 2px 4px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px !important;
    color: var(--plum-5);
  }

  .ql-mention-list-item.selected {
    background: var(--slate-0);
  }

  &.single-input {
    .ql-container {
      padding: 7px 30px 7px 12px;
    }
  }

  &.dynamic-suggestions {
    .ql-editor.ql-blank::after {
      content: "Press / to add dynamic values";
      // Positioning the text beside the add tag button as a placeholder.
      position: absolute;
      top: 12px;
      right: 40px;
      font-size: 12px;
      line-height: 1;
    }
  }
}

// TODO: Sachin: Common out styles and remove personalized-token-input class
quill-editor.personalized-token-input {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid var(--slate-2);
  border-radius: 8px;
  padding: 8px;
  position: relative;

  .ql-mention-list-container {
    top: calc(100% + 8px) !important;
  }

  .ql-toolbar {
    border: none;
    box-shadow: none;
  }

  .ql-container {
    border: none !important;
    position: static;
    max-height: 80px;
    overflow: auto;
  }

  &:not(.ql-disabled) .ql-editor {
    padding: 0 !important;
    min-height: unset;
  }
}

.mat-tooltip {
  font-size: 12px;
  margin: 8px;
  background-color: var(--slate-8);
  box-shadow: var(--box-shadow);
  padding: 16px !important;
  max-width: 350px !important;

  &.mw-200 {
    max-width: 200px !important;
  }

  &.padding-small {
    padding: 8px !important;
  }
}

.tooltip-container {
  &.full-width {
    max-width: 100% !important;
  }

  &.spacing-pre {
    white-space: pre;
  }
}

.tooltip-text {
  position: absolute;
  left: 0;
  top: 125%;
  background: var(--slate-8);
  padding: 8px 10px;
  border-radius: 8px;
}

// tooltip based on data attribute. Use for dynamic html content.

[data-fello-tooltip] {
  position: relative;
}

[data-fello-tooltip]::after {
  visibility: hidden;
  padding: 5px 10px;
  background: var(--black);
  border-radius: 5px;
  font-size: 12px;
  line-height: 19px;
  color: var(--white);
  width: 250px;
  position: absolute;
  z-index: 999;
  left: 50%;
  // tooltip is aligned above text
  transform: translate(calc(-50% + var(--tooltip-left)), -100%);
  content: attr(data-fello-tooltip);
  word-break: break-word;
}

[data-fello-tooltip]:hover::after {
  visibility: visible;
}

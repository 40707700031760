.box-c {
  background-color: var(--background-dark-color);
  border-radius: var(--border-radius);
  padding: 15px;

  p {
    font-size: 16px;
    font-weight: 400;
  }
}

.page-container {
  display: block;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 24px;
}

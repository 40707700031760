.mat-progress-bar {
  border-radius: var(--border-radius);

  &.mid {
    height: 7px;
  }

  &.green {
    .mat-progress-bar-buffer {
      background-color: var(--slate-1);
    }

    .mat-progress-bar-fill::after {
      background-color: var(--green);
    }
  }
}

.transparent {
  .mat-select {
    font-size: 16px;
    color: var(--text-color);

    .mat-select-arrow {
      color: var(--color-primary);
    }
  }

  .mat-select-value {
    color: var(--text-color);
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    border-top: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  mat-select-trigger {
    display: flex;
    align-items: center;
  }
}

mat-select.border {
  background: var(--white);
  border: 1px solid var(--slate-2);
  border-radius: var(--border-radius);
  padding: 11px 15px;
  line-height: 28px;
}

.mat-select-panel-wrap {
  .mat-select-reset-bttn {
    padding: 16px;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    justify-content: left;
    position: sticky;
    bottom: 0;
    background: white;
    color: var(--link-color);
  }
}

.mat-select-panel,
.mat-autocomplete-panel {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
}


.dark {
  &.mat-select {
    padding: 14px;
    background: rgba(0, 0, 0, 0.6);
    color: var(--white);
    border: none;
    border-radius: 8px;

    .mat-select-value {
      color: var(--white);
    }

    .mat-select-arrow-wrapper {
      width: 30px;
      height: 30px;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--color-primary);

      .mat-select-arrow {
        border: solid var(--white);
        border-width: 0 1.5px 1.5px 0;
        margin: 2px 0 0 0;
        display: inline-block;
        padding: 3px;
        transform: translateY(-3px) rotate(45deg);
      }
    }
  }

  &.mat-option {
    background-color: var(--black);
    font-weight: 500;
    font-size: 16px;
    color: var(--white);

    .mat-option-text {
      color: var(--white);
    }

    &.mat-selected:not(.mat-option-multiple) {
      color: var(--white);
      background-color: var(--black);
    }

    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled) {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

.mat-select-panel-expended {
  max-width: unset;
}

.mat-select {
  &.select-no-outline {
    background: transparent;
    border: none;

    &:hover {
      .mat-select-arrow-wrapper {
        visibility: visible !important;
      }
    }

    .mat-select-placeholder {
      font-size: 14px;
    }

    &.show-arrow .mat-select-arrow-wrapper {
      visibility: visible;
      background: transparent;
    }

    &:not(.show-arrow) .mat-select-arrow-wrapper {
      visibility: hidden;
    }

    .mat-select-arrow-wrapper {
      background: var(--slate-1);
      height: 32px;
      width: 32px;
      border-radius: 4px;
      justify-content: center;

      .mat-select-arrow {
        border-color: var(--color-plum);
      }
    }
  }
}

.option-md {
  min-height: 60px !important;
  height: fit-content !important;
  padding: 5px 16px !important;
}

.mat-expansion-panel {
  &.expansion-panel-transparent {
    background: transparent;
    box-shadow: none;

    .mat-expansion-panel-header {
      height: auto;
      line-height: 1;
      flex-direction: row-reverse;
      justify-content: start;
      padding: 0;

      &-title {
        margin: 0 0 0 16px;
      }

      .mat-expansion-indicator {
        line-height: 0;
        margin-left: 2px;
      }

      &:hover {
        background: transparent;
      }
    }

    .mat-expansion-panel-body {
      font-size: 14px;
      padding: 0;
      margin-top: 8px;
    }
  }
}

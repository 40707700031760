ngu-tile {
  padding: 0 !important;

  .tile {
    box-shadow: none !important;
  }
}

.carousel-dots {
  display: flex;
  gap: 8px;
  margin: auto;
  justify-content: center;

  li {
    cursor: pointer;
    height: 8px;
    width: 8px;
    display: block;
    border-radius: 50%;
    background-color: var(--plum-1);
    transition: all ease 0.3s;

    &.dot-dashed {
      height: 6px;
      width: 40px;
      border-radius: 8px;
    }

    &.active {
      background-color: var(--color-primary);
    }
  }
}

@use '@angular/material' as mat;

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
// @include mat.core();

// define a real custom palette (using http://mcg.mbitson.com)
/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #fef0ee,
    100 : #fcdbd6,
    200 : #fac3ba,
    300 : #f8aa9e,
    400 : #f7988a,
    500 : #f58675,
    600 : #f47e6d,
    700 : #f27362,
    800 : #f06958,
    900 : #ee5645,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffdfdb,
    A700 : #ffc8c2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-secondary: (
    50 : #e8e8eb,
    100 : #c5c5cc,
    200 : #9f9eab,
    300 : #797789,
    400 : #5c5a6f,
    500 : #3f3d56,
    600 : #39374f,
    700 : #312f45,
    800 : #29273c,
    900 : #1b1a2b,
    A100 : #7a71ff,
    A200 : #4b3eff,
    A400 : #1b0bff,
    A700 : #1000f1,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$custom-typography: mat.define-typography-config(
  $font-family: var(--font-family),
  $headline: mat.define-typography-level(28px, 34px, 400),         /*H1*/
  $title: mat.define-typography-level(24px, 29px, 400),         /*H2*/
  $subheading-2: mat.define-typography-level(16px, 22px, 400),         /*H3*/
  $subheading-1: mat.define-typography-level(18px, 25px, 400),        /*H4*/
  $body-1: mat.define-typography-level(16px, 21px, 400),         /*.mat-body*/
  $caption: mat.define-typography-level(12px, 18.5px, 400),        /*.mat-caption*/
  $body-2: mat.define-typography-level(10px, 12px, 600), /*.mat-body-2*/

);
@include mat.core($custom-typography);

// mandatory stuff for theming
$bv-palette-primary: mat.define-palette($md-primary);
$bv-palette-accent:  mat.define-palette($md-secondary);

// include the custom theme components into a theme object
$bv-theme: mat.define-light-theme($bv-palette-primary, $bv-palette-accent);

// include the custom theme object into the angular material theme
@include mat.all-component-themes($bv-theme);

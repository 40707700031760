.map-buttons-c {
  background-color: var(--white);
  padding: 4px;
  border: 1px solid var(--slate-3);
  border-radius: 4px;
  margin: 0 8px 80px;

  button {
    border: none;
    border-radius: 0;
    padding: 6px 3px;

    &:not(:first-child) {
      border-top: 1px solid var(--slate-3);
    }
  }
}

.map-legends-c {
  border-radius: 5px;
  background: rgba(63, 61, 86, 0.85);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 24px;
  color: var(--white);

  .button-icon {
    height: 20px;
    width: 20px;
    min-width: 20px;
  }

  .legends {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .marker {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 2px;
    background-color: var(--slate-5);

    &.circle {
      border-radius: 50%;
    }

    //Propensity Score
    &.ps-very-high {
      background-color: var(--green-6);
    }

    &.ps-high {
      background-color: var(--green-4);
    }

    &.ps-moderate {
      background-color: var(--yellow-6);
    }

    &.ps-low {
      background-color: var(--orange-6);
    }

    &.ps-very-low {
      background-color: var(--red-6);
    }

    //MLS Status
    &.yellow-1 {
      background-color: var(--yellow-4);
    }

    &.blue-light {
      background-color: var(--blue);
    }

    &.red-light {
      background-color: var(--red);
    }

    &.red {
      background-color: var(--red);
    }

    &.slate-1 {
      background-color: var(--slate-3);
    }

    &.purple-1 {
      background-color: var(--purple);
    }
  }
}

:root {
  --postcard-title-size: 120px;
  --postcard-title-lines: 2;
}

.postcard-c {
  width: 1875px;
  height: 1275px;
  background: var(--white);
  border: 1px solid var(--slate-1);
  color: var(--black);
  position: relative;
  overflow: hidden;

  .content-editable-field {
    position: relative;
    border: 1px solid transparent;

    &:not(.disabled) {
      cursor: pointer;
    }

    &:hover,
    &.selected {
      border: 1px solid var(--blue-4);
      background: var(--blue-000);
    }

    .tag-label {
      font-size: 20px;
      border-radius: 4px 4px 0 0;
      background: var(--blue-4);
      color: var(--white);
      padding: 5px 10px;
      position: absolute;
      bottom: 100%;
      left: -1px;
      display: flex;
      gap: 0 5px;
      align-items: center;
      font-weight: 700;
    }
  }

  .title {
    color: var(--black);
    font-family: Helvetica, serif;
    font-size: var(--postcard-title-size);
    font-weight: 700;
    line-height: 1;
    height: calc((var(--postcard-title-size) * var(--postcard-title-lines)) + (var(--postcard-title-size) / 10));
  }

  &.front .title {
    white-space: pre-wrap;
    overflow: hidden;
  }

  .logo-c {
    height: 85px;
    width: 100%;
    max-width: 600px;

    img {
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .qr-code-c {
    height: 240px;
    width: 240px;
    border: 1px dashed var(--plum);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    gap: 20px;

    .qr-tag {
      padding: 6px 20px;
      border-radius: 100px;
      background: var(--blue-1);
      font-size: 22px;
      font-weight: 700;
      color: var(--color-secondary);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  .arrow {
    height: 75px;
  }

  .website-url {
    max-width: 600px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
  }

  .long-website {
    display: none !important;
  }

  .signature-scaled-up {
    transform: scale(1.7);
    transform-origin: left top;
  }

  lib-agent-signature .website, lib-agent-signature .email {
    color: black !important;
  }

  p {
    word-break: break-word;
  }
}


.workflow-step-container .postcard-c {
  border: 5px solid var(--slate-1);
}

.postcard-c,
.postcard,
.email {
  .agent-img-c {
    flex-direction: column;
    gap: 10px;
  }
}

.thumbnail-small {
  .postcard-c {
    border: 10px solid var(--slate-2);
    border-radius: 100px;
  }
}

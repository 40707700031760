@use "sass:math";

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-light-color);
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// Dimensions
@for $i from 1 through 20 {
  .h-#{$i * 5} {
    height: percentage(math.div($i, 20)) !important;
  }
}

@media (min-width: 992px) {
  @for $i from 1 through 20 {
    .h-lg-#{$i * 5} {
      height: percentage(math.div($i, 20)) !important;
    }
  }
}

@for $i from 1 through 20 {
  .w-#{$i * 5} {
    width: percentage(math.div($i, 20)) !important;
  }
}

$spaceamounts: (0, 4, 8, 16, 28, 48);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  $i: index($spaceamounts, $space);
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i - 1} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$i - 1} {
      padding-#{$side}: #{$space}px !important;
    }
  }

  .p-#{$i - 1} {
    padding: #{$space}px !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-4 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

//Display
.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

[hidden] {
  visibility: hidden !important;
}

//Flex
.flex-box {
  display: flex !important;

  &-center {
    display: flex !important;
    align-items: center !important;
  }

  &-all-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  &-justify-center {
    display: flex !important;
    justify-content: center !important;
  }

  &-between {
    display: flex !important;
    justify-content: space-between !important;
  }

  &-end {
    display: flex !important;
    justify-content: flex-end !important;
  }

  &-center-end {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  &-align-start {
    display: flex !important;
    align-items: flex-start !important;
  }

  &-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  &-center-around {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }

  &-top-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  &-end {
    display: flex !important;
    justify-content: flex-end !important;
  }

  &-direction-column {
    display: flex !important;
    flex-direction: column !important;
  }

  &-direction-row-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
  }

  &-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  @for $i from 1 through 5 {
    &-gap-#{$i} {
      gap: (math.div($i, 2)) + em;
    }
  }
}
@media (min-width: 992px) {
  .flex-box-lg {
    display: flex !important;
    &-center {
      display: flex !important;
      align-items: center !important;
    }

    &-all-center {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    &-justify-center {
      display: flex !important;
      justify-content: center !important;
    }

    &-between {
      display: flex !important;
      justify-content: space-between !important;
    }

    &-end {
      display: flex !important;
      justify-content: flex-end !important;
    }

    &-center-end {
      display: flex !important;
      justify-content: flex-end !important;
      align-items: center !important;
    }

    &-align-start {
      display: flex !important;
      align-items: flex-start !important;
    }

    &-center-between {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }

    &-center-around {
      display: flex !important;
      align-items: center !important;
      justify-content: space-around !important;
    }

    &-top-between {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }

    &-end {
      display: flex !important;
      justify-content: flex-end !important;
    }

    &-direction-column {
      display: flex !important;
      flex-direction: column !important;
    }

    &-direction-row {
      display: flex !important;
      flex-direction: row !important;
    }

    &-direction-row-reverse {
      display: flex !important;
      flex-direction: row-reverse !important;
    }

    &-start-between {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    @for $i from 1 through 5 {
      &-gap-#{$i} {
        gap: (math.div($i, 2)) + em;
      }
    }
  }
}

.flex-grow {
  flex-grow: 1;
}

.flex-item-fixed-sizing {
  min-height: 0;
  min-width: 0;
  flex: 0 0 auto;
}

.flex-item-auto-sizing {
  min-height: 0;
  min-width: 0;
  flex: 1 1 auto;
}

.flex-item-one-half-sizing {
  min-height: 0;
  min-width: 0;
  flex: 0.5 1 auto;
}

.flex-direction {
  &-row {
    flex-direction: row !important;
  }

  &-column {
    flex-direction: column !important;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}
@media (min-width: 767px) {
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
}
@media (max-width: 767px) {
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

@media (min-width: 767px) {
  .flex-lg-box-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 767px) {
  .flex-lg-box-top-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

//Object fit
.object-fit {
  &-contain {
    object-fit: contain !important;
  }

  &-cover {
    object-fit: cover !important;
  }
}

//Position
.position {
  &-relative {
    position: relative !important;
  }

  &-absolute {
    position: absolute !important;
  }
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// float
.float-right {
  float: right;
}

.float-left {
  float: left;
}

//Overflow
.overflow {
  &-hidden {
    overflow: hidden !important;
  }

  &-visible {
    overflow: visible !important;
  }

  &-auto {
    overflow: auto !important;
  }

  &-y-auto {
    overflow-y: auto !important;
  }

  &-x-auto {
    overflow-x: auto !important;
  }
}
.mandatory-x-scroll-snapping {
  scroll-snap-type: x mandatory;
}
.scroll-snap-align-start {
  scroll-snap-align: start;
}

//Curser
.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

//Borders
$widthamounts: (1, 2);
$sides: (top, bottom, left, right);

@each $width in $widthamounts {
  $i: index($widthamounts, $width);
  @each $side in $sides {
    .border-#{$side}-#{$i} {
      border-#{$side}: #{$width}px solid var(--border-color) !important;
    }
  }
}

.buttons-c {
  button:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    display: flex;
    gap: 16px;
    flex-direction: row-reverse;

    button:not(:last-of-type) {
      margin: 0;
    }
  }
}

.container {
  max-width: 1380px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    max-width: 100%;
    display: block;

    .cards-wrapper {
      max-width: 100%;
    }
  }
}

.link {
  font-weight: 500;
  font-size: 16px;
  color: var(--link-color);
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 1;
  width: fit-content;

  &:not(.no-hover):hover {
    text-decoration: underline;
  }
}

.link-arrow {
  cursor: pointer;
  background: var(--white);
  border: 1px solid var(--slate-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 8px 15px 15px;
  border-radius: 10px;

  app-icon {
    color: var(--color-primary);
    font-size: 28px;
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

body {
  --color-primary: var(--salmon);
  --color-primary-dark: var(--salmon-dark);
  --color-primary-light: rgba(var(--salmon-rgb), 0.8);
  --color-secondary: var(--plum);
  --color-secondary-dark: var(--plum-dark);
  --font-family: var(--inter);
  --font-weight: var(--font-weight-500);
  --font-size: var(--font-size-14);
  --line-height: var(--line-height-1-5);
  --header-background-color: var(--white);
  --border-radius: var(--border-radius-8);
  --chat-border-radius: var(--border-radius-100);
  --placeholder-color: rgba(var(--dark-grey-rgb), 0.7);
  --box-shadow: var(--box-shadow-1);
  --text-color: var(--plum);
  --text-color-light: var(--plum-3);
  --text-color-light-2: var(--plum-4);
  --background-color: var(--white);
  --body-background-color: var(--slate-00);
  --background-light-color: rgba(var(--light-grey-rgb), 0.5);
  --background-dark-color: var(--border-light-grey);
  --border-color: var(--border-light-grey-rgb);
  --border-dark-color: var(--slate-3);
  --input-background-color: var(--light-grey);
  --link-color: var(--blue);
  --text-color-coral-7: var(--coral-7);
}

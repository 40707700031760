.db-card {
  padding: 24px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  border: 1px solid var(--slate-3);
  transition: all ease 0.3s;

  .db-card-body {
    margin-top: 24px;
  }

  &.collapse {
    .db-card-body {
      display: none;
    }
  }

  &.highlight {
    border: 1px solid var(--color-primary);
    box-shadow: 0px 1.5px 100px rgb(86 105 125 / 15%);
  }

  .db-card-title {
    color: var(--text-color);
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 4px;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .db-card-desc {
    color: var(--text-color-light-2);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &.header-highlight {
    padding: 0;
    overflow: hidden;

    .db-card-header {
      padding: 12px;
      background-color: var(--plum);
      color: var(--white);

      .db-card-title {
        color: var(--white);
      }
    }

    .db-card-body {
      margin: 0;
      padding: 24px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.list-item {
  display: flex;
  gap: 20px;

  & > * {
    width: 100%;
    max-width: 50%;
  }
}

.mw-200 {
  max-width: 200px;
}

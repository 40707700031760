.bill-c {
  background: #ffffff;
  border: 1px solid var(--slate-2);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

.bill-header {
  padding: 15px 24px;
  background-color: var(--color-secondary);
}

.bill-block {
  padding: 16px 24px;
  border-bottom: 1px solid var(--slate-2);

  &.dashed {
    border-bottom: 1px dashed var(--slate-2);
  }

  .line-break.dashed {
    border-top: 1px dashed var(--slate-2);
  }
}

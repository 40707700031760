.custom-theme {
  button,
  .button {
    &:not(.link) {
      border-radius: var(--theme-border-radius);
      color: var(--btn-text-color);

      &.button-primary {
        color: var(--plum);
        border-color: var(--color-primary);
        background: white;
        font-weight: 600;
      }
    }
  }

  .widget-input-c,
  input:not([felloTheme] *) {
    border-radius: var(--theme-border-radius);
  }
}

.mat-dialog-container {
  background-color: var(--white);
  color: var(--text-color);
  border-radius: var(--border-radius) !important;
  position: relative;
  margin-bottom: env(safe-area-inset-bottom);

  .mat-dialog-actions {
    margin-bottom: 0;
  }

  mat-dialog-title {
    font-size: 22px;
    line-height: 28px;
    color: var(--text-color);
    margin: 0;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.bg-slate {
  .mat-dialog-container {
    background-color: var(--slate-000);
  }
}

.bg-grey {
  .mat-dialog-container {
    background-color: var(--slate-00);
  }
}

.dialog-header-style {
  .dialog-header {
    background-color: var(--plum);
    color: var(--white);
    margin: -24px -24px 24px -24px;
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;

    button {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
      background: var(--coral-1);

      app-icon {
        font-size: 30px;
      }
    }
  }

  &.not-dialog {
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--box-shadow);
    margin-bottom: 30px;
    border: 1px solid var(--slate-3);
    display: flex;
    flex-direction: column;

    .dialog-header {
      margin: 0;
    }

    .desc-c {
      padding: 30px 20px;
    }
  }

  &.header-primary {
    .dialog-header {
      background-color: var(--color-primary);
      display: flex;
      flex-direction: column;
      justify-content: normal;

      button {
        color: var(--color-primary);
        border: none;
        background: var(--coral-1);
      }
    }
  }
}

.dialog-full-screen {
  .mat-dialog-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .close {
    color: var(--color-primary);
    border: none;
    background: var(--coral-1);

    app-icon {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    max-width: 100vw !important;
    height: 100%;
    width: 100%;

    .mat-dialog-container {
      border-radius: 0 !important;
    }

    .mat-dialog-content {
      max-height: 78vh;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.69);
}

@media (max-width: 767px) {
  .mobile-bottom-sheet {
    animation-name: slideInBottom;
    animation-duration: 0.2s;
    width: 100%;
    max-width: 100% !important;
    margin-top: auto;

    .mat-dialog-container {
      transition: none;
      border-radius: 30px 30px 0px 0px !important;
      max-height: 95vh;
    }
  }
}

@media (max-width: 767px) {
  .mobile-bottom-sheet-2 {
    animation-name: slideInBottom;
    animation-duration: 0.2s;
    width: 100%;
    max-width: 100% !important;
    margin-top: auto;

    .mat-dialog-container {
      transition: none;
      border-radius: 30px 30px 0px 0px !important;
      height: 80vh;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .mat-dialog-content {
        max-height: none;
        display: flex;
        flex-direction: column;
        min-height: 0;
        min-width: 0;
        flex: 1 1 auto;
        padding-bottom: 24px;
      }

      .mat-dialog-actions {
        display: flex;
        flex-direction: column-reverse;
        padding: 12px 24px 24px;
        margin: 0 -24px;
        box-shadow: 0px -4px 10px 0px rgba(178, 177, 187, 0.15);
        margin-top: 0;

        button {
          width: 100%;
        }
      }
    }
  }
}

.dialog-dark-backdrop {
  background: rgba(var(--secondary-rgb), 0.95);
}

.dialog-full-footer {
  animation-name: slideInRight;
  animation-duration: 0.3s;
  width: 100%;
  height: 100%;
  max-width: 100% !important;

  .mat-dialog-container {
    background-color: var(--slate-0);
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    border-radius: 0 !important;

    .mat-dialog-content {
      flex: 1;
      max-height: none;
      display: flex;
      flex-direction: column;

      .db-card {
        margin: 0;
        padding: 16px;
        width: 100%;

        &.flex-1 {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    mat-dialog-actions {
      background-color: var(--color-secondary);
      margin: 0 -24px;
      padding: 8px 24px;
    }
  }

  &.show-header {
    position: absolute !important;
    bottom: 0;
    height: calc(100% - 60px);
  }
}

// gallery dialog
.source-gallery-dialog {
  max-width: 90vw !important;
  width: 100%;

  .mat-dialog-content {
    max-height: 80vh !important;
    height: 80vh !important;
  }

  @media (max-width: 992px) {
    .mat-dialog-content {
      height: 70vh !important;
    }
  }
}

// get started dialog
.get-started-dialog {
  width: 100%;
}

//dialog-full
.dialog-full {
  width: 100%;
  height: 100vh;
  max-width: 100vw !important;
  background-color: var(--slate-000);

  .mat-dialog-container {
    margin: 24px;
    height: auto;
    padding: 0;
    box-shadow: none;
    background: transparent;
    border: 1px solid var(--slate-3);

    mat-dialog-content {
      background-color: var(--white);
      padding: 24px 24px 0;
      margin: 0;
      border: none;
      max-height: none;
      border-radius: var(--border-radius);
    }

    .mat-dialog-actions {
      padding: 24px;
      border-top: 1px solid var(--slate-3);
    }
  }

  &.container-dialog {
    .mat-dialog-container {
      margin: 0;
      border: 0;
      background: var(--slate-00);
    }
  }
}

//onboarding
.dialog-onboarding {
  @media (max-width: 991px) {
    max-width: 100vw !important;
    height: 100%;

    .mat-dialog-container {
      display: flex;
      flex-direction: column;
    }

    .mat-dialog-content {
      max-height: none;
    }
  }
}

//activities panel
.all-activites-dialog,
.manage-billing-dialog {
  height: 100vh;
  width: 650px;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100% !important;
  }

  .mat-dialog-container {
    padding: 16px !important;
    transform: none !important;
    border-radius: 0 !important;
  }

  .mat-dialog-content {
    max-height: none !important;
    margin: 0;
    padding: 0;
  }

  &.no-padding {
    .mat-dialog-container {
      padding: 0 !important;
    }
  }

  &.mw-400 {
    width: 400px;
  }

  &.mw-450 {
    width: 450px;
  }

  &.mw-460 {
    width: 460px;
  }
}

.dialog-transparent-header {
  padding: 0;

  .mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
  }

  .mat-dialog-content {
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: 0;
    margin: 0;
  }
}

.notify-dialog {
  width: 100%;

  @media (min-width: 992px) {
    max-width: 1050px !important;
  }
}

.dialog-full-from-bottom {
  animation-name: slideInBottom;
  animation-duration: 0.4s;
  margin-top: auto;
  max-width: 100vw !important;

  .mat-dialog-container {
    max-width: 1400px;
    margin: auto;
    height: 95vh;
    border-radius: 30px 30px 0px 0px !important;
  }

  &.h-98 {
    .mat-dialog-container {
      height: 98vh;
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;

    @media (min-width: 992px) {
      padding: 16px;
      border-bottom: 1px solid var(--slate-2);
      margin-bottom: 0;

      & > * {
        width: 25%;
      }

      .nav {
        width: 50%;
      }
    }

    @media (max-width: 991px) {
      flex-wrap: wrap;

      .nav {
        order: 3;
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) {
    width: 100%;
    max-width: 1400px !important;
    height: calc(100vh - 18px);

    .mat-dialog-container {
      padding: 0;
      margin: auto 0 0;
      border-radius: 8px 8px 0px 0px !important;
    }
  }

  @media (max-width: 767px) {
    min-width: 100vw !important;
  }
}

.padding-bottom-0 {
  .mat-dialog-container {
    padding-bottom: 0;
  }

  .mat-dialog-content {
    padding-bottom: 24px;
  }
}

.no-padding {
  .mat-dialog-container {
    padding: 0;
  }
}

// Right panel opening as bottom sheet in mobile
.right-panel-bottom-sheet {
  position: absolute !important;
  right: 0;
  animation-name: slideInRight;
  animation-duration: 0.2s;
  height: 100vh;

  &--small {
    width: 450px;
  }

  &--medium {
    width: 720px;
  }

  .mat-dialog-container {
    padding: 0px !important;
    transform: none !important;
    border-radius: 0 !important;
  }

  .mat-dialog-content {
    max-height: none !important;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 600px) {
    animation-name: slideInBottom;
    width: 100%;
    max-width: 100% !important;
    height: 95dvh;
    bottom: 0;

    .mat-dialog-container {
      border-radius: 30px 30px 0 0 !important;
    }
  }
}

//Right Panel
.dialog-right-panel {
  height: 100vh;
  width: 420px;
  animation-name: slideInRight;
  animation-duration: 0.2s;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100% !important;
  }

  .mat-dialog-container {
    padding: 24px !important;
    transform: none !important;
    border-radius: 0 !important;
  }

  .mat-dialog-content {
    max-height: none !important;
    margin: 0;
    padding: 0;
  }

  button.full-width {
    width: 100%;
  }

  &.no-padding {
    .mat-dialog-container {
      padding: 0 !important;
    }
  }

  &.mw-400 {
    width: 400px;
  }

  &.mw-450 {
    width: 450px;
  }

  &.mw-460 {
    width: 460px;
  }

  &.mw-1024 {
    width: 1024px;
  }
}

.dialog-overflow-visible {
  .mat-dialog-container {
    overflow: visible;
  }
  .mat-dialog-content {
    overflow: visible !important;
  }
}

//Dialog sid-panels
.dialog-right-in {
  animation-name: slideInRight;
}

.dialog-left-in {
  animation-name: slideInLeft;
}

.dialog-right-in,
.dialog-left-in {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  max-width: 1400px !important;
  height: 100vh;
  width: 100%;

  .mat-dialog-container {
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    height: 100vh;
    max-width: 1400px;
    padding: 0;
    margin-left: auto;
    border-radius: 0 !important;
  }

  .mat-dialog-content {
    max-height: none !important;
    margin: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid var(--slate-2);
    margin-bottom: 0;

    &.px-24 {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &.full-width {
    max-width: 100% !important;

    .mat-dialog-container {
      max-width: 100%;
    }
  }

  @media (max-width: 767px) {
    animation-name: slideInBottom;
    animation-duration: 0.3s;
    width: 100%;
    max-width: 100% !important;
    margin-top: auto;

    .mat-dialog-container {
      margin-top: auto;
      transition: none;
      max-height: 95vh;
    }

    .mat-dialog-actions {
      min-height: auto;
    }
  }
}

@use "../common/mixins" as *;

.tag {
  padding: 5px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;

  @include tag-modifiers($prefix: "-");

  &.square {
    border-radius: 4px;
  }

  &.tag-small {
    font-size: 10px;
    line-height: 12px;
    padding: 3px 10px;
  }

  &.tag-red {
    background-color: var(--red);
    color: var(--white);
  }

  &.tag-red-light {
    background-color: var(--red-1);
    color: var(--red-7);
  }

  &.tag--neutral {
    color: var(--white);
    background: var(--slate-5);
  }

  &.tag--hot {
    color: var(--white);
    background: linear-gradient(98.99deg, #f25917 17.49%, #ffa030 106.77%);
  }

  &.tag--cold {
    color: var(--white);
    background: linear-gradient(98.99deg, #1755f2 17.49%, #30c1ff 106.77%);
  }

  &.tag-orange-1 {
    background-color: var(--orange-1);
    color: var(--orange-7);
  }

  &.tag-plum-1 {
    color: var(--plum);
  }

  &.tag-blue-light {
    background-color: var(--blue-1) !important;
    color: var(--blue-7);
  }

  &.tag-green-light {
    color: var(--green-dark);
  }

  &.tag-slate-1 {
    color: var(--slate-7);
  }

  &.tag-coral-1 {
    color: var(--coral-7);
  }

  &.tag-status {
    background: var(--slate-0);
    border: 1px solid var(--slate-2);
    font-weight: 700;
  }

  &.tag-yellow-1 {
    color: var(--yellow-7);
  }

  &.tag-purple-1 {
    color: var(--purple);
  }

  &.tag-gradient {
    background: linear-gradient(255deg, #f58675 -24.4%, #311e90 134.43%);
  }
}

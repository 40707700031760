.hamburger-menu {
  background: var(--color-primary);
  border-radius: 10px;
  height: 50px;
  width: 50px;
  padding: 6px 13px;
  z-index: 99;

  .bar1 {
    width: 21px;
    height: 3px;
    background-color: var(--white);
    margin: 7px 0;
    transition: 0.4s;
    border-radius: 5px;
  }

  .bar2 {
    width: 21px;
    height: 3px;
    background-color: var(--white);
    margin: 7px 0;
    transition: 0.4s;
    border-radius: 5px;
    width: 10px;
  }

  .bar3 {
    width: 21px;
    height: 3px;
    background-color: var(--white);
    margin: 7px 0;
    transition: 0.4s;
    border-radius: 5px;
    width: 16px;
  }
}

.hamburger-menu.open {
  background: var(--color-primary);

  .bar1 {
    transform: rotate(-45deg) translate(-7px, 8px);
  }
  .bar2 {
    opacity: 0;
  }
  .bar3 {
    width: 21px;
    transform: rotate(45deg) translate(-6px, -7px);
  }
}

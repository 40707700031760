.mat-slider-track-fill,
.mat-slider-wrapper,
.mat-slider-track-wrapper,
.mat-slider-track-background {
  height: 6px !important;
  border-radius: 1000px;
}

.mat-slider.mat-slider {
  padding: 0;

  &.primary {
    .mat-slider-track-fill {
      background-color: var(--color-primary) !important;
    }
  }

  &.secondary {
    .mat-slider-track-fill {
      background-color: var(--color-secondary) !important;
    }

    .mat-slider-track-background {
      background-color: var(--slate-1) !important;
    }
  }
}

.mat-slider-thumb.mat-slider-thumb {
  bottom: -12px;
  width: 24px;
  height: 24px;
  border: 3px solid var(--color-primary) !important;
  background-color: var(--white) !important;
  border-radius: 50%;
  transform: scale(1);
}

.tab-pills {
  .mat-tab-header {
    border: none;
    margin-bottom: 24px;
  }

  .mat-tab-labels {
    background: rgba(0, 0, 0, 0.6);
    padding: 6px;
    width: fit-content;
    gap: 8px;
    border-radius: 50px;

    .mat-tab-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      padding: 12px 26px;
      border-radius: 50px;
      color: var(--white);
      opacity: 1;
      transition: all ease 0.3s;

      &.mat-tab-label-active {
        background-color: var(--color-primary);
        color: var(--btn-text-color);
      }
    }
  }

  .mat-ink-bar,
  .mat-tab-header-pagination {
    display: none;
  }

  &.style-white {
    .mat-tab-header {
      margin-bottom: 16px;
    }

    .mat-tab-labels {
      background: var(--white);
      padding: 4px;
      width: fit-content;
      gap: 8px;
      border-radius: 100px;

      border: 1px solid var(--slate-2);
      background: var(--white);
      box-shadow: 0 1.5px 3px 0 rgba(86, 105, 125, 0.15);

      .mat-tab-label {
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        padding: 6px 10px;
        border-radius: 60px;
        color: var(--color-secondary);
        height: auto;
        min-width: 80px;

        &.mat-tab-label-active {
          background-color: var(--color-secondary);
          color: var(--white);
        }
      }
    }
  }

  &.style-secondary {
    .mat-tab-header {
      margin-bottom: 16px;
    }

    .mat-tab-labels {
      background: var(--color-secondary);
      padding: 4px;
      width: fit-content;
      gap: 8px;
      border-radius: 100px;

      border: 1px solid var(--slate-2);
      background: var(--color-secondary);
      box-shadow: 0 1.5px 3px 0 rgba(86, 105, 125, 0.15);

      .mat-tab-label {
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        padding: 6px 10px;
        border-radius: 60px;
        color: var(--white);
        height: auto;
        min-width: 80px;

        &.mat-tab-label-active {
          background-color: var(--color-primary);
          color: var(--white);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .mat-tab-labels {
      width: 100%;

      .mat-tab-label {
        width: 100%;
      }
    }
  }
}

.tabs-style-square {
  .mat-tab-header {
    border: none;
    margin-bottom: 24px;
  }

  .mat-tab-labels {
    width: fit-content;

    .mat-tab-label {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      padding: 6px 12px;
      color: var(--plum-4);
      border-radius: 0;
      border: 1px solid var(--slate-2);
      opacity: 1;
      transition: all ease 0.3s;
      height: auto;
      min-width: auto;
      background: var(--white);

      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }

      &.mat-tab-label-active {
        background-color: var(--coral-000);
        color: var(--color-primary);
        border: 1px solid var(--color-primary);
      }
    }
  }

  .mat-ink-bar,
  .mat-tab-header-pagination {
    display: none;
  }

  &.full-width-labels {
    .mat-tab-labels {
      width: 100%;

      .mat-tab-label {
        width: 100%;
      }
    }
  }

  &.h-100 {
    .mat-tab-body-wrapper {
      min-height: 0;
      min-width: 0;
      flex: 1 1 auto;
    }
  }

  @media (max-width: 767px) {
    .mat-tab-labels {
      width: 100%;

      .mat-tab-label {
        width: 100%;
      }
    }
  }
}

.mat-tab-group.tab-group-small {
  &.stick-header-top {
    .mat-tab-header {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  .mat-tab-header {
    max-width: 1140px;
    padding: 0 calc((100% - 1140px) / 2);
    box-sizing: content-box;
    background: var(--background-color);

    .mat-tab-labels {
      gap: 16px;
    }

    .mat-tab-label {
      height: 43px;
      padding: 0 12px;
      min-width: unset;
    }
  }

  .mat-tab-body-wrapper {
    flex: 1 1 auto;
  }
}

.tabs-disabled {
  pointer-events: none;
}

@use "./buttons";
@use "../common/general";
@use "../common/text";

.introjs-prevbutton {
  @extend .button-primary;
}

.introjs-skipbutton.introjs-skipbutton,
.introjs-skipbutton.introjs-skipbutton:hover {
  @extend .button-icon;
  @extend .flex-box-all-center;
  color: var(--color-primary);
  border: none;
  background: var(--coral-1);
  width: 30px;
  height: 30px;
  min-width: 30px;
  font-size: 20px;
  font-weight: 400;
}

.introjs-tooltiptext {
  @extend .text-color-light;
}

.introjs-tooltipReferenceLayer {
  &,
  * {
    font-family: inherit !important;
  }
  .introjs-tooltipbuttons {
    padding: 16px;
  }
  .introjs-tooltiptext {
    padding: 16px;
  }
  .introjs-tooltip-header {
    padding: 16px 16px 0 16px;
    @extend .flex-box-between;
  }
  .introjs-skipbutton {
    position: static;
  }
}

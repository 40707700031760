.line-break {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--slate-3);

  &.dashed {
    border-top: 2px dashed var(--slate-2);
  }

  &.large {
    margin-top: 32px;
    padding-top: 32px;
  }

  &.small {
    margin-top: 16px;
    padding-top: 16px;
  }

  &.x-small {
    margin-top: 8px;
    padding-top: 8px;
  }
}

.vertical-line-break {
  margin: 0 8px;
  height: 100%;
  border-left: 1px solid var(--border-color);
}

.overlay-panel {
  border-radius: 12px;
  background: var(--white);
  box-shadow: 0px 1.5px 7px rgba(86, 105, 125, 0.35);
  flex-direction: column;

  .panel-body {
    padding: 12px 16px;
  }

  .panel-footer {
    padding: 12px 16px;
    border-top: 1px solid var(--slate-1);
  }

  &.w-500 {
    width: 500px;
  }

  &.w-350 {
    width: 350px;
  }

  &.w-300 {
    width: 300px;
  }

  &.caret-top {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid var(--white);
    }
  }
}

.step {
  padding-left: 24px;
  position: relative;

  .complete-tick {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    &::before {
      content: "";
      position: absolute;
      height: calc(100% + 24px);
      width: 2px;
      left: 6px;
      top: 15px;
      background: var(--slate-2);
      opacity: 0.4;
    }
  }

  &:not(.complete) {
    .title {
      font-weight: 700;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    background: var(--slate-2);
    top: 6px;
    left: 0;
    border-radius: 50%;
  }

  &.active {
    &::after {
      border: 3px solid var(--color-primary);
      background-color: var(--white);
    }
  }

  &.complete {
    .complete-tick {
      display: contents;
    }

    .complete-tick span {
      line-height: 1;
      position: absolute;
      left: -3px;
      top: 4px;
      font-size: 20px;
      color: var(--plum-7) !important;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: var(--white);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }
}

.stepper-full-height {
  .mat-horizontal-stepper-wrapper {
    height: 100%;
  }

  .mat-horizontal-content-container {
    flex: 1 1 auto;
  }

  .mat-horizontal-stepper-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.stepper-disable-header-navigation {
  .mat-horizontal-stepper-header {
    pointer-events: none;
    background-color: transparent;
  }
}

.stepper-collapsed-spacing {
  &.mat-stepper-horizontal {
    .mat-horizontal-stepper-wrapper {
      align-items: flex-start;
    }

    .mat-horizontal-stepper-header {
      height: unset;
      padding: 0;

      .mat-step-icon {
        color: var(--white);
      }

      .mat-step-icon-state-number {
        background: var(--plum-2);
      }

      .mat-step-icon-selected {
        background: var(--color-primary);
      }

      .mat-step-label {
        font-size: 14px;
        color: var(--plum-5);
        min-width: auto;
      }

      .mat-step-label-selected,
      .mat-step-label-active {
        font-size: 14px;
        color: var(--color-primary);
      }
    }

    .mat-horizontal-content-container {
      padding: 0;
      margin-top: 24px;
      width: 100%;
    }

    .mat-stepper-horizontal-line {
      margin: 0 10px;
    }
  }
}

.mat-expansion-panel.mat-expanded {
  background: var(--slate-000);
}

.mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}

.mat-accordion.plain {
  .mat-expansion-panel-header {
    padding: 0 8px;
  }

  .mat-expansion-panel.mat-expanded {
    background: transparent;
  }

  .mat-expansion-panel-body {
    padding: 8px;
  }
}

.mat-accordion.category {
  .mat-expansion-panel-header {
    padding: 12px;
    height: auto;

    &:hover {
      background-color: var(--slate-000) !important;
    }
  }

  .mat-expansion-panel.mat-expanded {
    background: transparent;
  }

  .mat-expansion-panel-body {
    padding: 12px;
  }
}

.mat-accordion.settings {
  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded {
    padding: 0 4px;
    flex-direction: row-reverse;
    gap: 8px;
    height: 48px;

    .mat-expansion-indicator::after {
      margin-bottom: 6px;
    }
  }

  &:not(.arrow-plum) {
    .mat-expansion-panel-header,
    .mat-expansion-panel-header.mat-expanded {
      .mat-expansion-indicator::after {
        color: var(--blue);
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.panel-collapsible-block {
  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded {
    padding: 12px 20px;
    flex-direction: row-reverse;
    gap: 12px;
    height: 55px;
    background: transparent;

    .mat-expansion-indicator::after {
      margin-bottom: 6px;
    }
  }

  &:not(.arrow-plum) {
    .mat-expansion-panel-header,
    .mat-expansion-panel-header.mat-expanded {
      .mat-expansion-indicator::after {
        color: var(--blue);
      }
    }
  }

  .mat-expansion-panel-header-title {
    margin: 0;
  }

  .mat-expansion-indicator {
    transform: rotate(-90deg) !important;
  }

  &.mat-expanded {
    background: transparent;

    .mat-expansion-indicator {
      transform: rotate(0deg) !important;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 20px 20px;
  }
}

mat-expansion-panel.draggable {
  mat-expansion-panel-header {
    position: relative;
    height: 38px;

    .drag-icon {
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      cursor: grab;
      width: 25px;
      z-index: 10;
    }

    &:hover {
      background-color: var(--slate-1) !important;

      .mat-expansion-indicator {
        opacity: 0;
      }

      .drag-icon {
        opacity: 1;
      }
    }
  }

  &.cdk-drag-dragging,
  &.cdk-drag-preview {
    pointer-events: auto !important;
    background-color: var(--slate-00) !important;

    .drag-icon {
      cursor: grabbing !important;
    }

    .no-results {
      display: none;
    }
  }
}

.mat-accordion.arrow-styles .mat-expansion-indicator::after {
  margin-top: -6px;
}

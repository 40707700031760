button,
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
  background-color: var(--color-primary);
  padding: 16px 25px;
  border-radius: var(--border-radius);
  cursor: pointer;

  &:disabled:not(.fake-disabled) {
    pointer-events: none;
    filter: opacity(0.5);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}

.button.button-mid,
button.button-mid {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 12px;
  border-radius: var(--border-radius);
}

.button.button-small,
button.button-small {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 12px;
  border-radius: 4px;
}

.button.button-primary,
button.button-primary {
  border: 1px solid var(--color-primary);
  background-color: var(--coral-0);
  color: var(--color-secondary);
}

.button.button-primary-light,
button.button-primary-light {
  border: 1px solid var(--color-primary);
  background-color: var(--white);
  color: var(--color-primary);
}

.button.button-plain,
button.button-plain {
  border: none;
  background-color: transparent;
  color: var(--color-secondary);
}

.button-primary-dark,
.button-primary-dark.button-icon {
  background: var(--coral-1);
  color: var(--primary);
}

.button.button-secondary,
button.button-secondary {
  border: 1px solid var(--slate-3);
  background-color: var(--body-background-color);
  color: var(--color-secondary);
}

.button.button-tag,
button.button-tag {
  padding: 10px 16px;
  border-radius: 100px;
  font-size: 12px;
  white-space: nowrap;
}

.button.button-transparent,
button.button-transparent {
  border: 1px solid var(--color-primary);
  background-color: transparent;
  color: var(--text-color);
}

.button.button-white,
button.button-white {
  background-color: var(--white);
  color: var(--color-secondary) !important;
  border: 1px solid var(--slate-3);
}

.button.button-red,
button.button-red {
  background-color: var(--red);
  color: var(--white);
  border: 1px solid var(--slate-3);
}

.button-icon {
  color: var(--white);
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  height: 32px;
  width: 32px;
  min-width: 32px;
  padding: 0;
  border-radius: 50%;
  transition: all ease 0.3s;

  &:not(.fake-disabled):disabled {
    background-color: var(--slate-00);
    color: var(--plum-2) !important;
    cursor: auto;
    pointer-events: auto;
  }
}

.button-icon.button-small {
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
  padding: 0;
  font-size: 14px;

  img {
    width: 15px;
  }

  &.disabled {
    color: var(--slate-00) !important;
    cursor: default !important;
  }
}

.button-icon.button-primary {
  background-color: var(--coral-0);
  color: var(--color-secondary);
}

.button-icon.button-secondary {
  color: var(--color-primary);
  background-color: var(--background-dark-color);
}

.button-icon.button-slate-1 {
  color: var(--color-secondary);
  background-color: var(--slate-1);
}

.button-icon.button-square {
  border-radius: 4px;
  aspect-ratio: 1/1;

  &.button-small {
    height: 20px;
    width: 20px;
    min-width: 20px;
  }
}

.dot {
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: var(--color-primary);

  &.dot--big {
    height: 9px;
    width: 9px;
    min-width: 9px;
  }

  &.dot--xl {
    height: 10px;
    width: 10px;
    min-width: 10px;
  }
}

.button-dashed {
  border: 1px dashed var(--blue);
  background: var(--blue-000);
  color: var(--blue);
}

.form-field {
  position: relative;
  width: 100%;

  &.prefix {
    input[type="text"] {
      padding-left: 35px;
    }
  }

  &:after {
    content: attr(data-prefix);
    position: absolute;
    top: 15px;
    left: 15px;
    color: var(--color-secondary);
    font-size: 20px;
    line-height: 28px;
  }

  &.suffix {
    input[type="text"] {
      padding-right: 35px;
    }
  }

  &:before {
    content: attr(data-suffix);
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--color-secondary);
    font-size: 20px;
    line-height: 28px;
  }
}

input:not([felloTheme] *),
textarea:not([felloTheme] *) {
  font-family: var(--font-family);
  background: var(--input-background-color);
  border-radius: 10px;
  padding: 16px 15px;
  color: var(--color-secondary);
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  border: none;
  outline: none;
  resize: none;
  margin: 0;

  &::placeholder {
    color: var(--placeholder-color);
    font-weight: 400;
  }

  &:disabled:not(.fake-disabled) {
    opacity: 0.5;
    pointer-events: none;
  }

  &.fake-disabled:disabled {
    cursor: pointer;
  }

  &.disabled-grey {
    &:disabled {
      background: var(--slate-00);
      opacity: 1;
    }
  }
}

input:-webkit-autofill:not([felloTheme] *),
input:-webkit-autofill:hover:not([felloTheme] *),
input:-webkit-autofill:focus:not([felloTheme] *),
input:-webkit-autofill:active:not([felloTheme] *) {
  -webkit-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
  -webkit-text-fill-color: var(--color-secondary) !important;
}

@media (min-width: 768px) {
  input[type="text"]:not([felloTheme] *),
  input[type="password"]:not([felloTheme] *) {
    font-size: 18px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"]:not([felloTheme] *) {
  -moz-appearance: textfield;
}

.number-counter {
  display: flex;
  color: var(--color-secondary);
  width: 165px;
  border: 1px solid var(--slate-2);
  border-radius: 8px;
  overflow: hidden;

  button {
    height: 40px;
    width: 40px;
    background: var(--slate-0);
    border-radius: 0;
    color: var(--blue);
  }

  input:not([felloTheme] *) {
    padding: 0;
    border: none;
    text-align: center;
    width: fit-content;
  }

  .value-c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    border-left: 1px solid var(--slate-2);
    border-right: 1px solid var(--slate-2);
  }
}

.widget-input-c {
  max-width: 650px;

  @container (max-width: 600px) {
    input:not([felloTheme] *) {
      padding: 15px 12px;
    }

    button {
      margin: 16px 0 0;
      width: 100%;
      position: relative;
    }
  }

  @container (min-width: 600px) {
    background: var(--white);
    border: 1px solid var(--slate-2);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    color: var(--color-secondary);
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    padding: 5px 6px;

    button {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      padding: 16px 25px;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translate(0, -50%);
      margin: 0;
      width: auto;
    }

    input:not([felloTheme] *) {
      border: none;
      padding: 16px 15px;
      color: var(--color-secondary);
      width: 100%;
      font-size: 20px;
      line-height: 26px;
      background: transparent;
    }
  }

  input[type="color"]:not([felloTheme] *) {
    padding: 0;
    height: 20px;
    cursor: pointer;
    width: 20px;
  }

  input[type="color"]::-webkit-color-swatch {
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    border-radius: 4px;
    padding: 0;
  }
}

.border-1 {
  border-radius: var(--border-radius);
  padding: 8px;
  border: 1px solid var(--slate-2);
}

.border-left-1 {
  border-left: 1px solid var(--slate-2);
}

.border-right-1 {
  border-right: 1px solid var(--slate-2) !important;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--slate-2) !important;

  &.dashed {
    border-bottom: 1px dashed var(--slate-2) !important;
  }
}

.border-color-primary {
  border-color: var(--color-primary) !important;
}

.rounded {
  --border-radius: 8px;
}

.semi-rounded {
  --border-radius: 4px;
}

.squared {
  --border-radius: 0;
}

.fully-rounded {
  --border-radius: 40px;
}

.textarea-count {
  position: absolute;
  padding: 4px;
  right: 4px;
  bottom: 6px;
  background-color: var(--white);
  text-align: right;
  border-radius: 10px;
  width: fit-content;
  margin: auto;
}

textarea:has(+ .textarea-count) {
  padding-bottom: 25px;
}

//Input Block Styles
.input-prefix-block,
.input-suffix-block {
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--slate-2);
  background: var(--slate-00);
}

.input-prefix-block {
  border-radius: 8px 0 0 8px;

  & + input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    &:focus {
      border: 1px solid var(--color-secondary);
    }
  }
}

.input-suffix-block {
  border-radius: 0 8px 8px 0;
}

lib-number-input:has(+ .input-suffix-block) input,
input:has(+ .input-suffix-block) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &:focus {
    border: 1px solid var(--color-secondary);
  }
}

.input-prefix-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-suffix-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

input:has(+ .input-suffix-icon) {
  padding-right: 35px;
}

.input-prefix-icon + input {
  padding-left: 35px;
}
